import { auth } from "@/http";
import {
  UnauthorizedError,
  DeviceUpdateForbiddenError,
  DeviceDeleteForbiddenError,
  EnvironmentNotFoundError,
  RequiredFieldMissingError,
  DeviceNameExistsError,
  DeviceParametersExistsError,
  IdNotFoundError,
  PortRangeOverlapError,
  InvalidDevicesRecordFileError,
  BrokenRfRecordingFileUrlError,
  FailedToDownloadRfRecordingError
} from "@/utils/error";
import { environmentId } from "@/utils";
import axios from "axios";

/**
 * Fetch Devices associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchDevices() {
  return auth.get("config/" + environmentId() + "/devices").catch(error => {
    if (error.response.status === 401) {
      throw new UnauthorizedError();
    } else if (error.response.status === 404) {
      if (error.response.data.error === 5) {
        throw new EnvironmentNotFoundError();
      } else throw new Error();
    } else throw new Error();
  });
}

/**
 * Adds Device
 * @param {Object} data Device data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {DeviceNameExistsError} if device name already exists
 * @throws {DeviceParametersExistsError} if device parameters already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {PortRangeOverlapError} if device port range overlaps with other device
 * @throws {Error}
 * @returns {Promise<any> | Promise<T | T>}
 */
function addDevice(payload) {
  return auth
    .post("config/" + environmentId() + "/devices", payload)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else if (error.response.data.error === 50) {
          throw new DeviceNameExistsError();
        } else if (error.response.data.error === 51) {
          throw new DeviceParametersExistsError();
        } else if (error.response.data.error === 52) {
          throw new PortRangeOverlapError();
        } else throw new Error();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Updates devices
 * @param {Object} data device data
 * @throws {UnauthorizedError} if permission denied
 * @throws {DeviceUpdateForbiddenError} if the user doesn't have the privilege to update the device
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {DeviceNameExistsError} if device name already exists
 * @throws {DeviceParametersExistsError} if device parameters already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {PortRangeOverlapError} if device port range overlaps with other device
 * @throws {Error}
 * @returns {Promise}
 */
function updateDevice(deviceId, payload) {
  return auth
    .put(`config/${environmentId()}/devices/${deviceId}`, payload)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 403) {
        throw new DeviceUpdateForbiddenError();
      } else if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else if (error.response.data.error === 50) {
          throw new DeviceNameExistsError();
        } else if (error.response.data.error === 51) {
          throw new DeviceParametersExistsError();
        } else if (error.response.data.error === 52) {
          throw new PortRangeOverlapError();
        } else throw new Error();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Deletes devices
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {DeviceDeleteForbiddenError} if the user doesn't have the privilege to delete the device
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function deleteDevice(id) {
  return auth
    .delete("config/" + environmentId() + "/devices/" + id)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 403) {
        throw new DeviceDeleteForbiddenError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Fetch halo data links availability
 * @param devices
 * @return {Promise}
 */
function fetchHaloDataLinksAvailability(devices) {
  return auth
    .get("config/" + environmentId() + "/devices/data_links_availability", {
      params: {
        devices: devices
      }
    })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Fetch halo data links info
 * @param devices
 * @return {Promise}
 */
function fetchHaloDataLinksInfo(devices) {
  return auth
    .get("config/" + environmentId() + "/devices/data_links_info", {
      params: {
        devices: devices
      }
    })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Fetch device status
 * @param devices
 * @return {Promise}
 */
function fetchDevicesStatus(devices) {
  return auth
    .get("config/" + environmentId() + "/devices/device_status", {
      params: {
        devices: devices
      }
    })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

function importDevices(data) {
  return auth
    .post("config/" + environmentId() + "/devices/import", data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    })
    .catch(error => {
      if (error.response.status === 400) {
        if (error.response.data.error === 53) {
          throw new InvalidDevicesRecordFileError();
        } else throw new Error();
      } else if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

function fetchRfRecordings(params = { device_id: null }) {
  return auth
    .get("config/" + environmentId() + "/rf_data_recordings", {
      params
    })
    .catch(error => {
      if (error.response.status === 400) {
        throw new Error();
      } else if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

function downloadRfRecordings(data = { report_uuids: null, device_id: null }) {
  return auth
    .post(`config/${environmentId()}/rf_data_recordings_download`, data, {
      responseType: "blob"
    })
    .catch(async error => {
      if (error.response.status === 400) {
        const data = JSON.parse(await error.response.data.text());
        if (data.error === 111) {
          throw new FailedToDownloadRfRecordingError();
        }
        throw new Error();
      } else if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

function checkRfRecordingUrlToFile(url) {
  return axios.get(url).catch(() => {
    throw new BrokenRfRecordingFileUrlError();
  });
}

function fetchDevicesState() {
  return auth
    .get("config/" + environmentId() + "/devices/devices_state")
    .catch(error => {
      if (error.response.status === 400) {
        throw new Error();
      } else if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

export default {
  fetchDevices,
  addDevice,
  updateDevice,
  deleteDevice,
  fetchHaloDataLinksAvailability,
  fetchHaloDataLinksInfo,
  fetchDevicesStatus,
  importDevices,
  fetchRfRecordings,
  downloadRfRecordings,
  checkRfRecordingUrlToFile,
  fetchDevicesState
};
