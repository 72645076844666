<template>
  <v-autocomplete
    class="ml-3 primary"
    v-model="search"
    solo-inverted
    color="grey"
    :label="$t('actions.search')"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    hide-details
    clearable
    :items="searchItems"
    hide-no-data
    autocomplete="off"
  >
    <template v-slot:item="data">
      <v-list-item v-on="data.on" @click="searchAction(data.item, data)">
        <v-list-item-content>
          <span>{{ data.item.text }}</span>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>{{ data.item.icon }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { permissionMixin } from "@/mixins/permissions.js";
export default {
  name: "VSearch",
  mixins: [permissionMixin],
  data() {
    return {
      search: null,
      searchInput: null
    };
  },

  created() {
    if (this.isPermitted(this.PermissionEnum.USERS.VIEW)) {
      this.$store.dispatch("users/fetchAll");
    }
    if (this.isPermitted(this.PermissionEnum.ENVIRONMENTS.VIEW)) {
      this.$store.dispatch("environments/fetchAll");
    }
    if (this.isPermitted(this.PermissionEnum.DEVICES.VIEW)) {
      this.$store.dispatch("devices/fetchAll");
    }
    if (this.isPermitted(this.PermissionEnum.APPLICATION_RULES.VIEW)) {
      this.$store.dispatch("dataPath/fetchAll");
    }
    if (this.isPermitted(this.PermissionEnum.OPENVPN_PROFILE.VIEW)) {
      this.$store.dispatch("dataPath/fetchVpnProfile");
    }
  },

  computed: {
    usersList() {
      return this.$store.state.users.users.map(data => {
        data.entityType = "users";
        data.icon = "mdi-account-circle";
        data.text = data.username;
        return data;
      });
    },
    environmentsList() {
      return this.$store.state.environments.environments.map(data => {
        data.entityType = "environments";
        data.icon = "mdi-domain";
        data.text = data.name;
        return data;
      });
    },
    devicesList() {
      return this.$store.state.devices.devices.map(data => {
        data.entityType = "devices";
        data.icon = "mdi-devices";
        data.text = data.name;
        return data;
      });
    },

    groupsList() {
      return this.$store.state.groups.groups.map(data => {
        data.entityType = "groups";
        data.icon = "mdi-layers";
        data.text = data.name;
        return data;
      });
    },

    applicationRulesList() {
      return this.$store.state.dataPath.applicationRules.map(data => {
        data.entityType = "applicationRules";
        data.icon = "mdi-book-open-variant";
        data.text = data.name;
        return data;
      });
    },

    vpnProfilesList() {
      return this.$store.state.dataPath.vpnProfile.vpnProfiles.map(data => {
        data.entityType = "vpnProfiles";
        data.icon = "mdi-key";
        data.text = data.name;
        return data;
      });
    },

    searchItems() {
      return [
        ...this.devicesList,
        ...this.usersList,
        ...this.environmentsList,
        ...this.applicationRulesList,
        ...this.vpnProfilesList
      ];
    }
  },

  methods: {
    searchAction(item) {
      switch (item.entityType) {
        case "users":
          this.$store.commit("users/updateSearch", item.text);
          break;
        case "devices":
          this.$store.commit("devices/updateSearch", item.text);
          break;
        case "environments":
          this.$store.commit("environments/updateSearch", item.text);
          break;
        case "applicationRules":
          this.$store.commit("dataPath/updateSearch", item.text);
          break;
        case "vpnProfiles":
          this.$store.commit("dataPath/updateVpnProfileSearch", item.text);
          break;
        default:
          break;
      }
      this.$router
        .push({
          name:
            item.entityType === "applicationRules" ||
            item.entityType === "vpnProfiles"
              ? "dataPath"
              : item.entityType
        })
        .then(() => (this.search = null))
        .catch(() => {});
    }
  }
};
</script>

<style scoped></style>
