import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./app.vue";
import router from "./http/router";
import store from "./store/index";
import i18n from "./plugins/i18n";
import Vuebar from "vuebar";
import "flag-icon-css/sass/flag-icons.scss";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import * as VueGoogleMaps from "vue2-google-maps";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAP_API_KEY
  },
  installComponents: true
});

Vue.use(Vuebar);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  vuetify
}).$mount("#app");
