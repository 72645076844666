import { auth } from "@/http";
import {
  UnauthorizedError,
  EnvironmentNotFoundError,
  RequiredFieldMissingError,
  GroupNameExistsError,
  IdNotFoundError
} from "@/utils/error";
import { environmentId } from "@/utils";

/**
 * Fetch Groups associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchGroups() {
  return auth.get("config/" + environmentId() + "/groups").catch(error => {
    if (error.response.status === 401) {
      throw new UnauthorizedError();
    } else if (error.response.status === 404) {
      if (error.response.data.error === 5) {
        throw new EnvironmentNotFoundError();
      }
    } else throw new Error();
  });
}

/**
 * Adds Group
 * @param {Object} data Group data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {GroupNameExistsError} if group name already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise<any> | Promise<T | T>}
 */
function addGroup(data) {
  let formattedData = {
    group: {
      name: data.name
    }
  };
  return auth
    .post("config/" + environmentId() + "/groups", formattedData)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else if (error.response.data.error === 40) {
          throw new GroupNameExistsError();
        }
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      } else throw new Error();
    });
}

/**
 * Updates groups
 * @param {Object} data group data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {GroupNameExistsError} if group name already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function updateGroup(data) {
  let formattedData = {
    group: {
      name: data.name
    }
  };
  return auth
    .put("config/" + environmentId() + "/groups/" + data.id, formattedData)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else if (error.response.data.error === 40) {
          throw new GroupNameExistsError();
        }
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      } else throw new Error();
    });
}

/**
 * Deletes groups
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function deleteGroup(id) {
  return auth
    .delete("config/" + environmentId() + "/groups/" + id)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      } else throw new Error();
    });
}

export default {
  fetchGroups,
  addGroup,
  updateGroup,
  deleteGroup
};
