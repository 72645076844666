const EMAIL_REGEX_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Creates an instance representing an error that occurs when user credentials are incorrect.
 */
export class UserCredentialsError extends Error {}

export class UserEnvironmentError extends Error {}

export default {
  emailPattern: EMAIL_REGEX_PATTERN,
  errors: {
    UserCredentialsError,
    UserEnvironmentError
  }
};
