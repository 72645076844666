import service from "./service";

const defaults = {};

const module = {
  namespaced: true,

  state: { ...defaults },

  actions: {
    verifyOTP(context, payload) {
      return service.verifyOTP(payload);
    }
  }
};

export default module;
