<script>
import { mapState } from "vuex";

export default {
  name: "ConfirmDialog",

  computed: {
    ...mapState({
      visible: state => state.common.confirm.visible,
      title: state => state.common.confirm.title,
      message: state => state.common.confirm.message,
      confirmationCallback: state => state.common.confirm.confirmationCallback
    })
  },

  methods: {
    confirm() {
      if (this.confirmationCallback) {
        this.confirmationCallback(true);
      }
      this.hideConfirmationDialog();
    },

    cancel() {
      if (this.confirmationCallback) {
        this.confirmationCallback(false);
      }
      this.hideConfirmationDialog();
    },
    hideConfirmationDialog() {
      this.$store.commit("common/updateConfirm", { visible: false });
    }
  }
};
</script>

<template>
  <v-dialog v-model="visible" max-width="350" persistent id="confirm-dialog">
    <v-card>
      <v-card-title id="confirm-dialog">{{ title }}</v-card-title>
      <v-card-text id="confirm-dialog">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="cancel" id="confirm-dialog__no">
          {{ $t("actions.no") }}
        </v-btn>
        <v-btn color="primary" @click="confirm" id="confirm-dialog__yes">{{
          $t("actions.yes")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
