<template>
  <v-app :dark="isDarkTheme">
    <v-navigation-drawer
      fixed
      v-model="drawerVisible"
      app
      clipped
      :mini-variant="miniDrawer === true"
    >
      <v-list dense>
        <v-list-item :to="'/'">
          <v-list-item-action>
            <v-tooltip right :disabled="miniDrawer === false">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-home</v-icon>
              </template>
              <span>{{ $t("layouts.app.home") }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("layouts.app.home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="navigate('environments')"
          :class="$route.name === 'environments' ? 'v-list-item--active' : ''"
          :disabled="!isPermitted(PermissionEnum.ENVIRONMENTS.VIEW)"
        >
          <v-list-item-action>
            <v-tooltip right :disabled="miniDrawer === false">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" slot="activator"> mdi-domain </v-icon>
              </template>
              <span>{{ $t("layouts.app.environments") }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("layouts.app.environments")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="navigate('users')"
          :class="$route.name === 'users' ? 'v-list-item--active' : ''"
          :disabled="!isPermitted(PermissionEnum.USERS.VIEW)"
        >
          <v-list-item-action>
            <v-tooltip right :disabled="miniDrawer === false">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-account-circle </v-icon>
              </template>
              <span>{{ $t("layouts.app.users") }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("layouts.app.users") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="navigate('devices')"
          :class="$route.name === 'devices' ? 'v-list-item--active' : ''"
          :disabled="!isPermitted(PermissionEnum.DEVICES.VIEW)"
        >
          <v-list-item-action>
            <v-tooltip right :disabled="miniDrawer === false">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-devices </v-icon>
              </template>
              <span>{{ $t("layouts.app.devices") }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("layouts.app.devices")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="navigate('groups')"
          v-if="false"
          :class="$route.name === 'groups' ? 'v-list-item--active' : ''"
        >
          <v-list-item-action>
            <v-tooltip right :disabled="miniDrawer === false">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-layers </v-icon>
              </template>
              <span>{{ $t("layouts.app.groups") }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("layouts.app.groups")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="navigate('dataPath')"
          :class="$route.name === 'dataPath' ? 'v-list-item--active' : ''"
          :disabled="
            !(
              isPermitted(PermissionEnum.APPLICATION_RULES.VIEW) ||
              isPermitted(PermissionEnum.OPENVPN_PROFILE.VIEW)
            )
          "
        >
          <v-list-item-action>
            <v-tooltip right :disabled="miniDrawer === false">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-axis-arrow-lock</v-icon>
              </template>
              <span>{{ $t("layouts.app.dataPath") }}</span>
            </v-tooltip>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("layouts.app.dataPath")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-group>
          <template v-slot:prependIcon>
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-shield-check</v-icon>
                </template>
                <span>{{ $t("layouts.app.admin") }}</span>
              </v-tooltip>
            </v-list-item-action>
          </template>

          <template v-slot:activator>
            <v-list-item-title
              >{{ $t("layouts.app.admin") }}
            </v-list-item-title>
          </template>
          <v-list-item
            :to="{ name: 'versionsManagement' }"
            :disabled="!isPermitted(PermissionEnum.ADMIN.VIEW)"
          >
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-numeric-9-plus-box-multiple</v-icon>
                </template>
                <span>{{ $t("layouts.app.versionManagement") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("layouts.app.versionManagement")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="{ name: 'thresholds' }"
            :disabled="!isPermitted(PermissionEnum.ADMIN.VIEW)"
          >
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-clipboard-pulse</v-icon>
                </template>
                <span>{{ $t("layouts.app.thresholds") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("layouts.app.thresholds")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :to="{ name: 'usageMonitoring' }"
            :disabled="!isPermitted(PermissionEnum.ADMIN.VIEW)"
          >
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-account-clock</v-icon>
                </template>
                <span>{{ $t("layouts.app.usageMonitoring") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("layouts.app.usageMonitoring")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group>
          <template v-slot:prependIcon>
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-clipboard-text</v-icon>
                </template>
                <span>{{ $t("layouts.app.logs") }}</span>
              </v-tooltip>
            </v-list-item-action>
          </template>

          <template v-slot:activator>
            <v-list-item-title>{{ $t("layouts.app.logs") }} </v-list-item-title>
          </template>
          <v-list-item
            :to="{ name: 'auditLog' }"
            :disabled="!isPermitted(PermissionEnum.LOGS.VIEW)"
          >
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-clipboard-account</v-icon>
                </template>
                <span>{{ $t("layouts.app.auditLog") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("layouts.app.auditLog")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{ name: 'systemLog' }"
            :disabled="!isPermitted(PermissionEnum.LOGS.VIEW)"
          >
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-view-list</v-icon>
                </template>
                <span>{{ $t("layouts.app.systemLog") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("layouts.app.systemLog")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{ name: 'messagingLog' }"
            :disabled="!isPermitted(PermissionEnum.MESSAGING_LOG.VIEW)"
          >
            <v-list-item-action>
              <v-tooltip right :disabled="miniDrawer === false">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-message-text-clock-outline</v-icon>
                </template>
                <span>{{ $t("layouts.app.messagingLog") }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("layouts.app.messagingLog")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dark color="primary" fixed clipped-left app>
      <v-app-bar-nav-icon
        @click.stop="toggleDrawer()"
        id="navbar__hamburger"
      ></v-app-bar-nav-icon>
      <img :src="require('@/assets/allsight-logo2-light.svg')" :width="200" />
      <v-search></v-search>
      <v-spacer></v-spacer>
      <span
        class="message-content yellow--text pl-2"
        v-html="display_message"
      ></span>
      <v-toolbar-items>
        <v-btn
          icon
          :disabled="!is_loading"
          :loading="is_loading"
          :ripple="false"
          id="navbar__global-loading"
        ></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="my-auto">
              {{ parseClock(remote_clock_time) }}
            </span>
          </template>
          <span>{{ $t("serverClock") }}</span>
        </v-tooltip>
        <v-language-switcher
          disabled
          text
          v-model="locale"
          :initial-locales="locales"
        />
        <v-profile />
      </v-toolbar-items>
    </v-app-bar>

    <v-dialog v-model="logout_dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{ $t("actions.logout") }}</v-card-title>
        <v-card-text>{{ $t("validation.logoutMessage") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            elevation="1"
            @click="logout_dialog = false"
          >
            {{ $t("actions.cancel") }}
          </v-btn>
          <v-btn color="secondary" text elevation="1" @click="logout">{{
            $t("actions.logout")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main>
      <transition
        mode="out-in"
        name="router-anim"
        enter-active-class="animated fadeIn"
        :duration="200"
      >
        <router-view />
      </transition>
    </v-main>
    <versions-dialog></versions-dialog>
    <confirm-dialog></confirm-dialog>
    <settings-dialog></settings-dialog>
  </v-app>
</template>

<script>
import VLanguageSwitcher from "@/layouts/app/components/v-language-switcher";
import VProfile from "./components/v-profile";
import VSearch from "./components/v-search";
import { handleError } from "@/utils/error";
import moment from "moment";
import VersionsDialog from "./components/versions-dialog.vue";
import Messages from "!raw-loader!@/../public/messages.txt";
import { permissionMixin } from "@/mixins/permissions";
import ConfirmDialog from "@/components/confirm-dialog.vue";
import SettingsDialog from "./components/allsight-settings/settings-dialog.vue";

export default {
  name: "app-layout",
  mixins: [permissionMixin],
  components: {
    VSearch,
    VProfile,
    VLanguageSwitcher,
    VersionsDialog,
    ConfirmDialog,
    SettingsDialog
  },

  data() {
    return {
      locales: Object.keys(this.$i18n.messages),
      remote_clock_time: null,
      fetchServerClockIntervalId: 0,
      updateServerClockLocallyIntervalId: 0,
      messageIntervalId: 0,
      messageId: 0,
      display_message: "",
      messages: Messages
    };
  },
  computed: {
    logout_dialog: {
      get() {
        return this.$store.state.login.logout_dialog;
      },
      set(val) {
        this.$store.commit("login/updateLogoutDialog", val);
      }
    },

    isDarkTheme: function () {
      return this.$store.state.common.theme.isDark;
    },
    drawerVisible: {
      get() {
        return this.$store.state.common.sidebar.visible;
      },
      set(value) {
        this.$store.dispatch("common/updateSidebar", { visible: value });
      }
    },
    miniDrawer: {
      get() {
        return this.$store.state.common.sidebar.mini;
      },
      set(value) {
        this.$store.dispatch("common/updateSidebar", { mini: value });
      }
    },
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(value) {
        this.$i18n.locale = value;
      }
    },
    is_loading() {
      return this.$store.state.loader.is_loading_global;
    },
    last_recvd_remote_time() {
      return this.$store.state.appLayout.clock.last_recvd_remote_time;
    },
    last_remote_to_local_time() {
      return this.$store.state.appLayout.clock.last_remote_to_local_time;
    },
    twoFAStatus() {
      return this.$store.state.login.user.twoFactorAuth.setupStatus;
    }
  },
  created() {
    this.serverClock();
    this.updateServerClockLocally();
    this.updateMessages();
    this.$store.commit("appLayout/updateTwoFAStates", {
      setupStatus: this.twoFAStatus,
      step: this.twoFAStatus === 3 ? 3 : 1
    });
  },
  beforeDestroy() {
    clearInterval(this.fetchServerClockIntervalId);
    clearInterval(this.updateServerClockLocallyIntervalId);
    clearInterval(this.messageIntervalId);
  },
  methods: {
    navigate(name) {
      switch (name) {
        case "users":
          this.$store.commit("users/updateSearch", "");
          break;
        case "devices":
          this.$store.commit("devices/updateSearch", "");
          break;
        case "environments":
          this.$store.commit("environments/updateSearch", "");
          break;
        case "applicationRules":
          this.$store.commit("dataPath/updateSearch", "");
          break;
        default:
          break;
      }
      this.$router.push({ name: name }).catch(() => {});
    },
    serverClock() {
      const FETCH_SERVER_CLOCK_IN_SECONDS = 60;
      this.$store
        .dispatch("appLayout/fetchServerClock")
        .catch(error => handleError(error));
      if (this.fetchServerClockIntervalId > 0) {
        clearInterval(this.fetchServerClockIntervalId);
      }
      this.fetchServerClockIntervalId = window.setInterval(() => {
        this.$store
          .dispatch("appLayout/fetchServerClock")
          .catch(error => handleError(error));
      }, FETCH_SERVER_CLOCK_IN_SECONDS * 1000);
    },
    updateServerClockLocally() {
      if (this.updateServerClockLocallyIntervalId > 0) {
        clearInterval(this.updateServerClockLocallyIntervalId);
      }
      this.updateServerClockLocallyIntervalId = window.setInterval(() => {
        let current_local_time = new Date().getTime();
        let timeDifference =
          current_local_time - this.last_remote_to_local_time;
        let totalTime = this.last_recvd_remote_time + timeDifference;
        this.remote_clock_time = totalTime;
      }, 1000);
    },
    parseClock(time) {
      return moment(time).utc().format("D MMM, YYYY HH:mm:ss [(UTC)]");
    },

    toggleDrawer() {
      if (!this.$vuetify.breakpoint.mdAndDown) {
        this.miniDrawer = !this.miniDrawer;
      } else {
        this.drawerVisible = !this.drawerVisible;
      }
    },

    logoutDialog() {
      this.logout_dialog = true;
    },
    logout() {
      this.$store.dispatch("login/logout").then(() => {
        this.logout_dialog = false;
        window.location.href = "/login";
      });
    },

    updateMessages() {
      this.messageIntervalId = setInterval(() => {
        let splited_messages = this.messages.split("\n");
        this.display_message = splited_messages[this.messageId++];
        this.messageId %= splited_messages.length;
      }, 2000);
    }
  }
};
</script>

<style scoped>
@import "~animate.css/source/animate.css";
.message-content {
  width: 420px;
}

@media screen and (max-width: 1400px) {
  .message-content {
    font-size: 13px;
  }
}
</style>
