<template>
  <div id="app">
    <component :is="component">
      <slot></slot>
    </component>
  </div>
</template>

<script>
import AppLayout from "@/layouts/app/layout";
import AuthLayout from "@/layouts/auth/layout";

export default {
  name: "app",

  components: {
    AuthLayout,
    AppLayout
  },

  computed: {
    component: function() {
      return this.$store.state.common.layout;
    },
    title: function() {
      return this.$store.state.common.title;
    },
    locale: function() {
      return this.$i18n.locale;
    }
  },

  watch: {
    title: function(title) {
      this.changeTitle(title);
    },
    locale: function(locale) {
      this.changeDirection(locale);
      this.changeTitle(this.title);
    }
  },

  methods: {
    /**
     * Changes application title.
     *
     * @param {String} titleLocaleMessage Title locale message, use "" for app default title
     * locale message.
     */
    changeTitle(titleLocaleMessage) {
      let title = "";

      if (titleLocaleMessage == "") {
        title = this.$t("app.documentTitle");
      } else {
        title = this.$t(titleLocaleMessage);
      }

      document.title = title;
    },

    /**
     * Changes application direction (right-to-left or left-to-right) according to locale.
     *
     * @param locale Locale by which the direction of the application will be determined.
     */
    changeDirection(locale) {
      var direction = "ltr";

      if (locale === "he-IL") {
        direction = "rtl";
      }

      this.$vuetify.rtl = direction === "rtl";
    }
  },

  mounted() {
    this.changeTitle(this.$store.state.common.title);
    this.changeDirection(this.$i18n.locale);
  }
};
</script>

<style>
.v-input--switch,
.v-input--checkbox {
  width: max-content;
}
</style>
