import { auth } from "@/http";
import { parentEnvironmentId } from "./util";
import { commonErrorHandler } from "@/utils";
/**
 * Fetch environments associated with parentEnvironmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchEnvironments(
  params = {
    object_id: 0,
    page_size: null,
    env_name: null
  }
) {
  return auth
    .get("config/" + parentEnvironmentId() + "/environments", { params })
    .catch(commonErrorHandler);
}

/**
 * Adds Environment
 * @param {Object} data Environment data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {EnvironmentExistsError} if environment already exists
 * @throws {EmailAlreadyExistsError} if email already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function addEnvironment(data) {
  let formattedData = {
    environment_name: data.name,
    username: data.username,
    password: data.password,
    email: data.email,
    description: data.description,
    ca_server_address: data.ca_server_address
  };
  return auth
    .post("config/" + parentEnvironmentId() + "/environments", formattedData)
    .catch(commonErrorHandler);
}

/**
 * Updates environment
 * @param {Object} data Environment data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {EnvironmentExistsError} if environment already exists
 * @throws {EmailAlreadyExistsError} if email already exists
 * @throws {UsernameAlreadyExistsError} if username already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {IdNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function updateEnvironment(data) {
  let formattedData = {
    environment_name: data.name,
    username: data.username,
    password: data.password || null,
    email: data.email,
    description: data.description,
    ca_server_address: data.ca_server_address
  };
  return auth
    .put(
      "config/" + parentEnvironmentId() + "/environments/" + data.id,
      formattedData
    )
    .catch(commonErrorHandler);
}

/**
 * Deletes the environment
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {IdNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function deleteEnvironment(id) {
  return auth
    .delete("config/" + parentEnvironmentId() + "/environments/" + id)
    .catch(commonErrorHandler);
}
function delete2FA(id) {
  return auth
    .post(`config/${parentEnvironmentId()}/environments/${id}/2fa/deactivate`)
    .catch(commonErrorHandler);
}

export default {
  fetchEnvironments,
  addEnvironment,
  updateEnvironment,
  deleteEnvironment,
  delete2FA
};
