import service from "@/modules/admin/versions-management/service";
import i18n from "@/plugins/i18n";

/* eslint-disable no-unused-vars */
const defaults = {
  versionsDefinitions: [],
  search: "",
  dialog: false,
  formTitle: i18n.t("modules.admin.versionManagement.add"),
  editedIndex: -1,
  editedItem: {
    version_definition: null
  },
  defaultItem: {
    version_definition: null
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    load(state, data) {
      state.versionsDefinitions = data;
    },
    addItem(state, data) {
      state.versionsDefinitions.push(data);
    },
    updateItem(state, item) {
      Object.assign(state.versionsDefinitions[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    fillForm(state, payload) {
      state.editedItem = Object.assign({}, payload);
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateVersionDefinition(state, value) {
      state.editedItem.version_definition = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateSearch(state, payload) {
      state.search = payload;
    },
    removeItem(state, id) {
      let index = state.versionsDefinitions.findIndex(x => x.id === id);
      state.groups.splice(index, 1);
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }) {
      return service.fetchVersionsDefinitions().then(response => {
        let data = response.data.version_definitions;
        commit("load", data);
      });
    },

    add({ state, commit, dispatch }, data) {
      return service.addVersionsDefinition(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.admin.versionManagement.addSuccess"),
              id: "add-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          commit("updateIndex", -1);
        });
      });
    },

    delete({ state, commit, dispatch }, id) {
      return service.deleteVersionsDefinition(id).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.admin.versionManagement.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
        });
      });
    },

    toggleDialog({ state, commit }, payload) {
      commit("toggleDialog", payload);
    },

    clearForm({ state, commit }) {
      commit("clearForm");
    }
  }
};

export default module;
