// Store for cross-application (non-business domain) states.

import * as theme from "@/theme/index";

const defaults = {
  sidebar: {
    mini: theme.MINI_DRAWER,
    visible: theme.DRAWER_VISIBILITY
  },
  theme: {
    /**
     * Whether theme is dark, otherwise it's light.
     */
    isDark: theme.IS_DARK
  },
  /**
   * Application title locale message.
   *
   * Empty string "" represents default application title locale message.
   */
  title: "",
  layout: "AppLayout",
  dialog: {
    visible: false,
    text: ""
  },
  snackbar: {
    visible: false,
    text: "",
    timeout: 6000,
    color: ""
  },
  error: {
    code: null,
    level: null,
    message: ""
  },
  confirm: {
    visible: false,
    title: "",
    message: "",
    confirmationCallback: null
  }
};

// Global module loaded on first app load.
export default {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    /**
     * Update application sidebar visibility.
     * @param {*} state Module state
     * @param {Object} options
     * @param {Boolean} options.visible Sidebar visibility
     */
    updateSidebar(state, options) {
      state.sidebar = { ...defaults.sidebar, ...options };
    },

    /**
     * Updates current theme.
     * @param {*} state Module state
     * @param {Object} options
     * @param {Boolean} options.dark Whether theme is dark.
     */
    updateTheme(state, options) {
      state.theme = { ...defaults.theme, ...options };
    },

    updateTitle(state, title) {
      state.title = title;
    },

    updateLayout(state, layout) {
      state.layout = layout;
    },

    updateDialog(state, options) {
      state.dialog = { ...defaults.dialog, ...options };
    },

    updateSnackbar(state, options) {
      state.snackbar = { ...defaults.snackbar, ...options };
    },

    error(state, options) {
      state.error = { ...defaults.error, ...options };
    },

    // eslint-disable-next-line no-unused-vars
    clear(state) {
      state = { ...defaults };
    },
    updateConfirm(state, options) {
      state.confirm = { ...defaults.confirm, ...options };
    }
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    clear({ state, commit, rootState, dispatch }) {
      commit("clear");
      dispatch("login/clear", {}, { root: true });
    },

    updateSidebar({ commit }, options) {
      commit("updateSidebar", options);
    },

    updateTitle({ commit }, title) {
      commit("updateTitle", title);
    },

    updateLayout({ commit }, layout) {
      commit("updateLayout", layout);
    },

    updateDialog({ commit }, options) {
      commit("updateDialog", options);
    },

    updateSnackbar({ commit }, options) {
      commit("updateSnackbar", options);
    },
    updateConfirm({ commit }, options) {
      commit("updateConfirm", options);
    }
  }
};
