import service from "@/modules/environments/service";
import i18n from "@/plugins/i18n";

/* eslint-disable no-unused-vars */
const defaults = {
  environments: [],
  currentEnvironment: {
    name: "",
    id: ""
  },
  search: "",
  dialog: false,
  formTitle: i18n.t("modules.environments.add"),
  editedIndex: -1,
  editedItem: {
    name: "",
    username: "",
    password: null,
    email: "",
    description: "",
    ca_server_address: "",
    two_factor_auth_status: ""
  },
  defaultItem: {
    name: "",
    username: "",
    password: null,
    email: "",
    description: "",
    ca_server_address: ""
  },
  hasChanges: false
};

const module = {
  namespaced: true,

  state: { ...defaults },

  getters: {
    environmentSearch(state) {
      return state.environments.map(data => {
        data.type = "environments";
        data.icon = "domain";
        data.text = data.name;
        return data;
      });
    },
    currentEnvironmentNameChanged(state, getters, rootState) {
      let environmentName = rootState.login.user.environment_name;

      return state.currentEnvironment.name !== environmentName;
    }
  },

  mutations: {
    load(state, data) {
      state.environments = data;
    },
    addItem(state, data) {
      state.environments.push(data);
    },
    updateItem(state, item) {
      Object.assign(state.environments[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    fillForm(state, payload) {
      state.editedItem = Object.assign({}, payload);
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateName(state, value) {
      state.editedItem.name = value;
    },
    updateUsername(state, value) {
      state.editedItem.username = value;
    },
    updatePassword(state, value) {
      state.editedItem.password = value;
    },
    updateEmail(state, value) {
      state.editedItem.email = value;
    },
    updateDescription(state, value) {
      state.editedItem.description = value;
    },
    updateCAServerAddress(state, value) {
      state.editedItem.ca_server_address = value;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateFormTitle(state, type) {
      if (type === 1) {
        state.formTitle = i18n.t("modules.environments.add");
      } else {
        state.formTitle = i18n.t("modules.environments.edit");
      }
    },

    updateCurrentEnvironment(state, value) {
      state.currentEnvironment = value;
    },
    updateCurrentEnvironmentName(state, value) {
      state.currentEnvironment.name = value;
    },
    updateChanges(state, payload) {
      state.hasChanges = payload;
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }, payload) {
      return service.fetchEnvironments(payload).then(response => {
        let data = response.data.environments;
        commit("load", data);
      });
    },

    add({ state, commit, dispatch }, data) {
      return service.addEnvironment(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.environments.addSuccess"),
              id: "add-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          commit("updateIndex", -1);
          commit("updateChanges", true);
        });
      });
    },

    update({ state, commit, dispatch, rootState }, data) {
      return service.updateEnvironment(data).then(response => {
        dispatch("fetchAll").then(() => {
          // Logged-in environment update
          var environmentId = rootState.login.user.environment_id;
          if (data.id === environmentId) {
            dispatch("login/updateEnvironmentName", data.name, { root: true });
          }

          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.environments.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          commit("updateIndex", -1);
          commit("updateChanges", true);
        });
      });
    },

    delete({ state, commit, dispatch }, id) {
      return service.deleteEnvironment(id).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.environments.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
        });
      });
    },
    delete2FA({ state, commit, dispatch }, id) {
      return service.delete2FA(id).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.environments.deleteSuccess2FA"),
              id: "delete-success-2fa"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          commit("updateIndex", -1);
          commit("updateChanges", true);
        });
      });
    },

    toggleDialog({ state, commit }, payload) {
      commit("toggleDialog", payload);
    },

    editItem({ state, commit }, payload) {
      payload.password = "";
      commit("fillForm", payload);
    },

    clearForm({ state, commit }) {
      commit("clearForm");
    },

    updateCurrentEnvironment({ state, commit }, value) {
      commit("updateCurrentEnvironment", value);
    },

    fetchCurrentEnvironment({ state, commit, rootState }) {
      let value = {
        id: rootState.login.user.environment_id,
        name: rootState.login.user.environment_name
      };
      commit("updateCurrentEnvironment", value);
    }
  }
};

export default module;
