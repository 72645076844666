import service from "./service";

const defaults = {
  satcom_messages: [],
  dialog: false,
  selected: {},
  defaultItem: {
    url: null,
    api_key: null
  },
  editedItem: {
    url: null,
    api_key: null
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    load(state, data) {
      state.satcom_messages = data;
    },
    updateDialog(state, payload) {
      state.dialog = payload;
    },
    updateSelected(state, payload) {
      state.selected = payload;
    },
    updateSatcomTargetUrl(state, payload) {
      state.editedItem.url = payload;
    },
    updateSatcomTargetApiKey(state, payload) {
      state.editedItem.api_key = payload;
    },
    fillSatcomTarget(state, data) {
      state.editedItem.url = data.url;
      state.editedItem.api_key = null;
    },
    clearSatcomTargetForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    }
  },

  actions: {
    fetchAll({ commit }) {
      return service.fetchSatcomMessages().then(response => {
        let data = response.data.satcom_messages;
        commit("load", data);
      });
    },
    setSatcomTarget({ dispatch }, data) {
      return service.setSatcomTarget(data).then(() => {
        dispatch("fetchSatcomTarget");
      });
    },
    clearSatcomTargetForm({ commit }) {
      commit("clearSatcomTargetForm");
    },
    fetchSatcomTarget({ commit }) {
      return service.fetchSatcomTarget().then(response => {
        commit("fillSatcomTarget", response.data.satcom_target);
      });
    }
  }
};
export default module;
