const state = {
  is_loading: false,
  is_loading_global: false
};

const mutations = {
  setLoading: (state, payload) => {
    state.is_loading = payload;
  },
  setGlobalLoading: (state, payload) => {
    state.is_loading_global = payload;
  }
};

const actions = {
  startLoading({ commit }) {
    commit("setLoading", true);
  },
  finishLoading({ commit }) {
    commit("setLoading", false);
  },
  startGlobalLoading({ commit }) {
    commit("setGlobalLoading", true);
  },
  finishGlobalLoading({ commit }) {
    commit("setGlobalLoading", false);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
