import Vue from "vue";
import Router from "vue-router";
import routes from "@/http/routes";
import store from "@/store";
import { TwoFAStateEnum } from "@/utils/enums.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: routes.map(route => ({
    name: route.name,
    path: route.path,
    component: route.component,
    beforeEnter: (to, from, next) => {
      // Setup some per-page stuff.
      store.dispatch("common/updateTitle", route.title);
      store.dispatch("common/updateLayout", route.layout);
      // Auth navigation guard.
      if (!route.isPublic) guardRoute(to, from, next);
      else next();
    }
  }))
});

/**
 * Guard the route from unauthorized users.
 *
 * @param {Route} to The route we want to access.
 * @param {Route} from The route from which we are coming from.
 * @param {Function} next Callback for passing a route to be called next.
 * @return {void}
 */
function guardRoute(to, from, next) {
  const login = store.state.login;
  let token = login.token.access_token;

  if (typeof login.isLoggedIn === "boolean") {
    store.commit("login/clear");
  }

  if (!token || login.isLoggedIn === TwoFAStateEnum.NOT_LOGGED_IN) {
    next({ name: "login" });
  } else if (login.isLoggedIn === TwoFAStateEnum.NOT_VERIFIED) {
    next({ name: "otpVerification" });
  } else {
    next();
  }
}

export default router;
