import store from "@/store";
import i18n from "@/plugins/i18n";

export function parentEnvironmentId() {
  return store.state.login.user.environment_id;
}

export function parseDeviceAttributeType(type) {
  switch (type) {
    case 1:
      return i18n.t("modules.admin.thresholds.temperature");
    case 2:
      return i18n.t("modules.admin.thresholds.deviceState");
    case 3:
      return i18n.t("modules.admin.thresholds.cellularPort1");
    case 4:
      return i18n.t("modules.admin.thresholds.cellularPort2");
    case 5:
      return i18n.t("modules.admin.thresholds.cellularPort3");
    case 6:
      return i18n.t("modules.admin.thresholds.cellularPort4");
    case 7:
      return i18n.t("modules.admin.thresholds.ethPort1");
    case 8:
      return i18n.t("modules.admin.thresholds.ethPort2");
    case 9:
      return i18n.t("modules.admin.thresholds.wifi");
    case 10:
      return i18n.t("modules.admin.thresholds.vlan");
    case 11:
      return i18n.t("modules.admin.thresholds.cellularModemsHealth");
    case 12:
      return i18n.t("modules.admin.thresholds.simEvent");
    default:
      return "";
  }
}

export default {
  parentEnvironmentId: parentEnvironmentId,
  parseDeviceAttributeType
};
