import service from "@/modules/reset-password/service";

const module = {
  namespaced: true,

  actions: {
    submit({ state, commit, dispatch }, credentials) {
      return service.submit(credentials).then(response => {});
    },

    checkUserToken(_, user_token) {
      return service.checkUserToken(user_token).then(response => {});
    }
  }
};

export default module;
