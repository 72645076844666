<script>
import { mapState } from "vuex";
import { handleError } from "@/utils/error";
import { mapFields } from "vuex-map-fields";

export default {
  name: "VProfile",
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true
    };
  },

  computed: {
    ...mapFields("appLayout", ["settings.settingsDialog"]),
    ...mapState("login", ["user", "realm_type"]),
    logout_dialog: {
      get() {
        return this.$store.state.login.logout_dialog;
      },
      set(val) {
        this.$store.commit("login/updateLogoutDialog", val);
      }
    }
  },

  methods: {
    logoutDialog() {
      this.logout_dialog = true;
    },
    openVersionDialog() {
      this.$store
        .dispatch("appLayout/fetchVersions")
        .then(() => this.$store.commit("appLayout/toggleVersionsDialog", true))
        .catch(error => handleError(error));
    }
  }
};
</script>

<template>
  <v-menu
    v-model="menu"
    :min-width="200"
    offset-y
    bottom
    :left="!$vuetify.rtl"
    fixed
    attach=".v-toolbar"
    :close-on-content-click="false"
  >
    <template #activator="{ on: menu }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            icon
            v-on="{ ...tooltip, ...menu }"
            id="navbar__profile-button"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ user.name + " " + $t("app.profile") }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title id="profile-popup__username">
              <v-icon>mdi-account-box</v-icon>
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              id="profile-popup__environment"
              class="d-flex"
            >
              <v-icon small class="ml-1">mdi-domain</v-icon>
              <span class="ml-2">
                {{ user.environment_name }}
              </span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }" class="ml-1">
                  <v-icon
                    small
                    v-on="on"
                    style="margin-left: auto"
                    v-if="realm_type === 1"
                    >mdi-cloud-outline</v-icon
                  >
                  <v-icon small v-on="on" style="margin-left: auto" v-else
                    >mdi-cloud-off-outline</v-icon
                  >
                </template>
                <span v-if="realm_type === 1">{{ $t("cloudRealm") }}</span>
                <span v-else>{{ $t("onPremisesRealm") }}</span>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="settingsDialog = true">
          <v-list-item-title>
            {{ this.$t("layouts.app.settings.title") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openVersionDialog()">
          <v-list-item-title>
            {{ $t("layouts.app.about.aboutAllsight") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions left>
        <v-btn
          color="primary"
          text
          @click="logoutDialog()"
          id="profile-popup__logout-button"
        >
          <v-icon>mdi-exit-to-app</v-icon>
          {{ $t("layouts.app.logout") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>