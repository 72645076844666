import { http } from "@/http";
import {
  UserCredentialsError,
  UserEnvironmentError
} from "@/modules/login/util";

import { IdNotFoundError } from "@/utils/error";

/**
 * Request reset token using given credentials.
 * @param {Object} credentials credentials object
 * @param {String} credentials.email
 * @param {String} credentials.environment
 * @throws {UserCredentialsError} if user credentials are incorrect
 * @throws {UserEnvironmentError} if user environment is incorrect
 * @throws {Error}
 * @returns {Promise}
 */
function forgot_password(credentials) {
  return http.post("/auth/forgot", credentials).catch(error => {
    if (error.response.status === 400) {
      if (error.response.data.error === 1) {
        throw new UserCredentialsError();
      } else if (error.response.data.error === 2) {
        throw new UserEnvironmentError();
      }
    } else if (error.response.status === 404) {
      if (error.response.data.error === 6) {
        throw new IdNotFoundError();
      }
    } else {
      throw new Error();
    }
  });
}

export default {
  forgot_password
};
