import service from "@/modules/login/service";
import { TwoFAStateEnum, TwoFAStatusEnum } from "@/utils/enums.js";

/* eslint-disable no-unused-vars */
const defaults = {
  isLoggedIn: TwoFAStateEnum.NOT_LOGGED_IN,
  logout_dialog: false,
  token: {
    access_token: null,
    token_type: null
  },
  user: {
    username: null,
    environment_id: null,
    environment_name: null,
    environment_manager: false,
    permissions: [],
    entities: {},
    twoFactorAuth: {
      setupStatus: 1
    }
  },
  realm_type: null
};

const module = {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    updateLogoutDialog(state, value) {
      state.logout_dialog = value;
    },
    update(state, data) {
      Object.keys(state).forEach(index => {
        state[index] = data[index];
      });
    },
    clear(state) {
      Object.keys(state).forEach(index => {
        state[index] = defaults[index];
      });
    },
    updateEnvironmentName(state, value) {
      state.user.environment_name = value;
    },
    updateTwoFAStatus(state, value) {
      state.user.twoFactorAuth.setupStatus = value;
    }
  },

  actions: {
    update({ commit }, data) {
      let loginDetails = {
        isLoggedIn: data.isLoggedIn,
        token: data.token,
        user: {
          name: data.name,
          environment_id: data.environment_id,
          environment_name: data.environment_name,
          environment_manager: data.environment_manager,
          permissions: data.permissions,
          entities: data.entities,
          twoFactorAuth: {
            setupStatus: data.two_factor_auth.setup_status
          }
        },
        realm_type: data.realm_type
      };

      commit("update", loginDetails);
    },
    login({ dispatch }, credentials) {
      return service.login(credentials).then(response => {
        let data = response.data;
        data.name = credentials.username;
        data.environment_name = credentials.environment_name;
        data.isLoggedIn =
          response.data.two_factor_auth.setup_status !== TwoFAStatusEnum.ACTIVE
            ? TwoFAStateEnum.LOGGED_IN
            : TwoFAStateEnum.NOT_VERIFIED;
        dispatch("update", data);
      });
    },
    logout({ commit }) {
      return service.logout().finally(response => {
        commit("clear");
      });
    },
    updateEnvironmentName({ state, commit }, value) {
      commit("updateEnvironmentName", value);
    }
  },
  getters: {
    loggedInUsersDevices: state => {
      return state.user.entities?.devices;
    }
  }
};

export default module;
