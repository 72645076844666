import { auth } from "@/http";
import { UnauthorizedError, EnvironmentNotFoundError } from "@/utils/error";
import { environmentId } from "@/utils";

/**
 * Fetch devicescconnectivity associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchDevicesConnectivity(
  params = {
    sub_environment_id: null
  }
) {
  return auth
    .get("config/" + environmentId() + "/devices_connectivity", { params })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      }
      throw new Error();
    });
}

export default {
  fetchDevicesConnectivity
};
