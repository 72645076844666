import axios from "axios";
import store from "@/store";
/**
 * Public HTTP endpoint.
 */
const http = axios.create({
  baseURL: process.env.VUE_APP_API_HOST
});

/**audit
 * Authorized HTTP endpoint.
 */
const auth = axios.create({
  baseURL: process.env.VUE_APP_API_HOST
});

if (auth !== undefined) {
  auth.interceptors.request.use(
    request => {
      store.dispatch("loader/startGlobalLoading");
      const token = store.state.login.token;
      request.headers.Authorization =
        token.token_type + " " + token.access_token;
      return request;
    },
    error => {
      store.dispatch("loader/finishLoading");
      store.dispatch("loader/finishGlobalLoading");
      return Promise.reject(error);
    }
  );

  auth.interceptors.response.use(
    response => {
      store.dispatch("loader/finishLoading");
      store.dispatch("loader/finishGlobalLoading");
      return response;
    },
    error => {
      store.dispatch("loader/finishLoading");
      store.dispatch("loader/finishGlobalLoading");
      return Promise.reject(error);
    }
  );
}

if (process.env.NODE_ENV !== "production") {
  http.interceptors.request.use(request => {
    console.log("Public SENT:", request);

    return request;
  });

  http.interceptors.response.use(response => {
    console.log("Public RECEIVED:", response);

    return response;
  });

  if (auth !== undefined) {
    auth.interceptors.request.use(request => {
      console.log("Authorized SENT:", request);
      return request;
    });

    auth.interceptors.response.use(response => {
      console.log("Authorized RECEIVED:", response);

      return response;
    });
  }
}

/**
 * File download HTTP endpoint.
 * Supports authorization by default.
 */
const file = {
  baseURL: process.env.VUE_APP_API_HOST,
  /**
   * Downloads a file from a given URL.
   */
  download: function(url) {
    return new Promise((resolve, reject) => {
      // Workaround for a programmatical file download.
      const anchor = document.createElement("a");
      anchor.href = this.baseURL + url;
      anchor.setAttribute("download", "");
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      console.log("FILE DOWNLOAD: " + url);
      resolve();
    });
  }
};

export { http, auth, file };
