import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

import theme from "@/theme/index";

Vue.use(Vuetify);
export default new Vuetify({
  options: {
    customProperties: true
  },
  rtl: false,
  // overrides default theme with custom theme.
  theme,
  icons: {
    iconfont: "mdi"
  }
});
