import service from "@/modules/users/service";
import i18n from "@/plugins/i18n";

/* eslint-disable no-unused-vars */
const defaults = {
  users: [],
  search: "",
  dialog: false,
  formTitle: i18n.t("modules.users.add"),
  editedIndex: -1,
  editedItem: {
    username: "",
    password: null,
    email: "",
    description: "",
    environment_manager: false,
    permissions: [],
    severity: 3,
    is_disabled: false,
    two_factor_auth_status: ""
  },
  defaultItem: {
    username: "",
    password: null,
    email: "",
    description: "",
    environment_manager: false,
    permissions: [],
    severity: 3,
    is_disabled: false
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    load(state, data) {
      state.users = data;
    },
    addItem(state, data) {
      state.users.push(data);
    },
    updateItem(state, item) {
      Object.assign(state.users[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    fillForm(state, payload) {
      state.editedItem = Object.assign({}, payload);
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateUsername(state, value) {
      state.editedItem.username = value;
    },
    updatePassword(state, value) {
      state.editedItem.password = value;
    },
    updateEmail(state, value) {
      state.editedItem.email = value;
    },
    updateDescription(state, value) {
      state.editedItem.description = value;
    },
    updatePermissions(state, value) {
      state.editedItem.permissions = value;
    },
    updateSeverity(state, value) {
      state.editedItem.severity = value;
    },
    updateIsDisabled(state, value) {
      state.editedItem.is_disabled = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateFormTitle(state, type) {
      if (type === 1) {
        state.formTitle = i18n.t("modules.users.add");
      } else {
        state.formTitle = i18n.t("modules.users.edit");
      }
    },
    updateSearch(state, payload) {
      state.search = payload;
    },
    removeItem(state, id) {
      let index = state.users.findIndex(x => x.id === id);
      state.users.splice(index, 1);
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }) {
      return service.fetchUsers().then(response => {
        let data = response.data.users;
        commit("load", data);
      });
    },

    add({ state, commit, dispatch }, data) {
      return service.addUser(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.users.addSuccess"),
              id: "add-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          /*let newData = {
            id: response.data.id,
            username: data.username,
            password: data.password,
            email: data.email,
            description: data.description,
            permissions: data.permissions,
            entities: {}
          };
          commit("addItem", newData);*/
          commit("updateIndex", -1);
        });
      });
    },

    update({ state, commit, dispatch }, data) {
      return service.updateUser(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.users.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          /*data.password = "";
          commit("updateItem", data);*/
          commit("updateIndex", -1);
        });
      });
    },

    delete({ state, commit, dispatch }, id) {
      return service.deleteUser(id).then(response => {
        dispatch("fetchAll").then(() => {
          // commit("removeItem", id);
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.users.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
        });
      });
    },

    toggleDialog({ state, commit }, payload) {
      commit("toggleDialog", payload);
    },

    editItem({ state, commit }, payload) {
      commit("fillForm", payload);
    },

    clearForm({ state, commit }) {
      commit("clearForm");
    },
    delete2FA({ state, commit, dispatch }, id) {
      return service.delete2FA(id).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.users.deleteSuccess2FA"),
              id: "delete-success-2fa"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          commit("updateIndex", -1);
        });
      });
    }
  }
};

export default module;
