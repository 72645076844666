<template>
  <v-dialog max-width="500" v-model="dialog" id="versions__dialog" persistent>
    <v-card>
      <v-card-title>
        {{ $t("layouts.app.about.aboutAllsight") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-subheader class="subtitle-1 black--text my-5 mx-2">
        {{ $t("layouts.app.about.version") }}:
        <v-chip
          label
          class="mx-4"
          @click="copy(official)"
          id="official__version"
          :disabled="!official"
          >{{ official || "N/A" }}
          <v-icon right small>mdi-content-copy</v-icon></v-chip
        >
      </v-subheader>
      <v-expansion-panels>
        <v-expansion-panel id="sub-version__panel">
          <v-expansion-panel-header id="sub-version__title">
            {{ $t("layouts.app.about.componentsVersions") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-subheader class="px-0">
              {{ $t("layouts.app.about.auth") }}:
              <v-chip
                label
                class="mx-4"
                id="auth__version"
                @click="copy(internal.auth)"
                :disabled="!internal.auth"
                >{{ internal.auth || "N/A"
                }}<v-icon right small>mdi-content-copy</v-icon></v-chip
              >
            </v-subheader>
            <v-subheader class="px-0">
              {{ $t("layouts.app.about.ca") }}:
              <v-chip
                label
                class="mx-4"
                id="ca__version"
                @click="copy(internal.ca)"
                :disabled="!internal.ca"
                >{{ internal.ca || "N/A"
                }}<v-icon right small>mdi-content-copy</v-icon></v-chip
              >
            </v-subheader>
            <v-subheader class="px-0">
              {{ $t("layouts.app.about.config") }} :
              <v-chip
                label
                class="mx-4"
                id="config__version"
                @click="copy(internal.config)"
                :disabled="!internal.config"
                >{{ internal.config || "N/A"
                }}<v-icon right small>mdi-content-copy</v-icon></v-chip
              >
            </v-subheader>
            <v-subheader class="px-0">
              {{ $t("layouts.app.about.monitoring") }} :
              <v-chip
                label
                class="mx-4"
                id="monitoring_version"
                @click="copy(internal.monitoring)"
                :disabled="!internal.monitoring"
                >{{ internal.monitoring || "N/A"
                }}<v-icon right small>mdi-content-copy</v-icon></v-chip
              >
            </v-subheader>
            <v-subheader class="px-0">
              {{ $t("layouts.app.about.ui") }} :
              <v-chip
                label
                class="mx-4"
                id="ui_version"
                @click="copy(uiVersion)"
                :disabled="!uiVersion"
                >{{ uiVersion || "N/A"
                }}<v-icon right small>mdi-content-copy</v-icon></v-chip
              >
            </v-subheader>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <div id="input__copy-content"></div>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeVersionDialog()">{{
          $t("actions.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="copy_notifier" :timeout="2000" id="copied__snackbar">
      {{ $t("modules.home.copied") }}
      <v-btn color="blue" text @click="copy_notifier = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import frontendVersion from "!raw-loader!@/../public/cloud_frontend.version";
export default {
  data() {
    return {
      copy_notifier: false,
      uiVersion: frontendVersion
    };
  },
  computed: {
    ...mapState({
      dialog: state => state.appLayout.versionsDialog.dialog,
      official: state => state.appLayout.versionsDialog.versions.official,
      internal: state => state.appLayout.versionsDialog.versions.internal
    })
  },
  methods: {
    closeVersionDialog() {
      this.$store.commit("appLayout/toggleVersionsDialog", false);
    },
    copy(text) {
      // Creates a temporary input to enable selection
      let input = document.createElement("input");
      input.setAttribute("type", "text");
      input.setAttribute("value", text);
      document.getElementById("input__copy-content").appendChild(input);
      input.select();
      let result = document.execCommand("copy");
      document.getElementById("input__copy-content").removeChild(input);
      this.copy_notifier = result;
      return result;
    }
  }
};
</script>

<style></style>
