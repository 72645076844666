import { auth } from "@/http";
import { UnauthorizedError, EnvironmentNotFoundError } from "@/utils/error";
import { environmentId } from "@/utils";

/**
 * Fetch User Log associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchSystemLog(
  params = {
    object_id: 0,
    device_name: null,
    device_ids: null,
    start_date: null,
    end_date: null,
    severities: null,
    device_attribute_types: null,
    page_size: null
  }
) {
  return auth
    .get("config/" + environmentId() + "/system_logs", { params })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      } else throw new Error();
    });
}

function fetchDeviceSystemLog(id, attributeType, object_id = 0, logTime) {
  return auth
    .get(
      "config/" +
        environmentId() +
        "/system_logs?device_ids=[" +
        id +
        "]&device_attribute_types=[" +
        attributeType +
        "]&object_id=" +
        object_id +
        "&end_date=" +
        logTime
    )
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      } else throw new Error();
    });
}

export default {
  fetchSystemLog,
  fetchDeviceSystemLog
};
