import { auth } from "@/http";
import {
  UnauthorizedError,
  EnvironmentNotFoundError,
  IdNotFoundError,
  MetaFileExistsError
} from "@/utils/error";
import { environmentId } from "@/utils";
import { GroupNameExistsError, RequiredFieldMissingError } from "@/utils/error";

/**
 * Fetch Versions Definitions associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchVersionsDefinitions() {
  return auth
    .get("config/" + environmentId() + "/admin/versions_definition")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      } else throw new Error();
    });
}

/**
 * Adds Version Definition
 * @param {Object} data Versions Definition data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {GroupNameExistsError} if group name already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise<any> | Promise<T | T>}
 */
function addVersionsDefinition(data) {
  return auth
    .post(
      "config/" + environmentId() + "/admin/versions_definition",
      data.formData
    )
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else if (error.response.data.error === 40) {
          throw new GroupNameExistsError();
        } else if (error.response.data.error === 70) {
          throw new MetaFileExistsError();
        }
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      } else throw new Error();
    });
}

/**
 * Deletes Versions Definition
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function deleteVersionsDefinition(id) {
  return auth
    .delete("config/" + environmentId() + "/admin/versions_definition/" + id)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      } else throw new Error();
    });
}

export default {
  fetchVersionsDefinitions,
  addVersionsDefinition,
  deleteVersionsDefinition
};
