import service from "./service";
import util from "./util";

const defaults = {
  devices_connectivity: [],
  search: ""
};

const module = {
  namespaced: true,

  state: { ...defaults },
  mutations: {
    loadDevicesConnectivity(state, data) {
      state.devices_connectivity = data;
      return state.devices_connectivity.map(data => {
        data.current_week = util.convertSecondsToHour(data.current_week);
        data.current_month = util.convertSecondsToHour(data.current_month);
        data.current_year = util.convertSecondsToHour(data.current_year);
        return data;
      });
    },
    updateSearch(state, payload) {
      state.search = payload;
    }
  },
  actions: {
    fetchDevicesConnectivity({ commit }, payload) {
      return service.fetchDevicesConnectivity(payload).then(response => {
        let data = response.data.connectivity_data;
        commit("loadDevicesConnectivity", data);
      });
    },
    fetchToDownload(context, payload) {
      return service.fetchDevicesConnectivity(payload);
    }
  }
};

export default module;
