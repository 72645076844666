// Default theme settings
import colors from "vuetify/lib/util/colors";

export const IS_DARK = false;
/**
 * null as the starting value will initialize the drawer as closed on mobile and as
 * open on desktop.
 */
export const DRAWER_VISIBILITY = null;
export const MINI_DRAWER = null;

export default {
  dark: IS_DARK,
  themes: {
    light: {
      primary: "#006FFF",
      secondary: "#F5A905",
      home__devices_status__online: colors.teal.base,
      home__devices_status__offline: colors.red.base,
      home__devices_status__activated: colors.blue.base,
      home__main_app_status__none: colors.grey.base,
      home__main_app_status__online: colors.green.base,
      home__main_app_status__offline: colors.red.base,
      audit_log__action__create: colors.blue.base,
      audit_log__action__update: colors.yellow.darken3,
      audit_log__action__delete: colors.red.base,
      audit_log__action__enable: colors.green.base,
      audit_log__action__disable: colors.grey.base,
      audit_log__action__text_color: colors.shades.white,
      logs__severity_normal: colors.blue.base,
      logs__severity_minor: colors.orange.base,
      logs__severity_major: colors.deepOrange.base,
      logs__severity_critical: colors.red.darken4,
      logs__severity_chip_color: colors.shades.white,
      system_log__overlay: colors.shades.white,
      system_log__chart_background: colors.shades.transparent,
      system_log__chart_border: colors.red.base
    }
  }
};
