import service from "@/modules/admin/thresholds/service";
import i18n from "@/plugins/i18n";

/* eslint-disable no-unused-vars */
const defaults = {
  thresholds: [],
  thresholds_metadata: [],
  search: "",
  dialog: false,
  formTitle: i18n.t("modules.admin.thresholds.add"),
  editedIndex: -1,
  editedItem: {
    device_attribute_type: null,
    threshold_values: null,
    should_alert: false
  },
  defaultItem: {
    device_attribute_type: null,
    threshold_values: null,
    should_alert: false
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },

  getters: {},

  mutations: {
    load(state, data) {
      state.thresholds = data;
    },
    loadMetadata(state, data) {
      state.thresholds_metadata = data;
    },
    updateItem(state, item) {
      Object.assign(state.thresholds[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    fillForm(state, payload) {
      state.editedItem.device_attribute_type = payload.device_attribute_type;
      state.editedItem.threshold_values = Object.assign(
        {},
        payload.threshold_values
      );
      state.editedItem.should_alert = payload.should_alert;
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateShouldAlert(state, payload) {
      state.editedItem.should_alert = payload;
    },
    updateSearch(state, value) {
      state.search = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateFormTitle(state, payload) {
      if (payload === 1) {
        state.formTitle = i18n.t("modules.admin.thresholds.add");
      } else if (payload === 2) {
        state.formTitle = i18n.t("modules.admin.thresholds.edit");
      } else {
        state.formTitle = payload;
      }
    },
    removeItem(state, id) {
      let index = state.thresholds.findIndex(x => x.id === id);
      state.thresholds.splice(index, 1);
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }) {
      return service.fetchThresholds().then(response => {
        let data = response.data.thresholds;
        commit("load", data);
      });
    },

    fetchThresholdsMetadata({ state, commit }) {
      return service.fetchThresholdsMetadata().then(response => {
        let data = response.data.thresholds_metadata;
        commit("loadMetadata", data);
      });
    },

    update({ state, commit, dispatch }, data) {
      return service.updateThreshold(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.admin.thresholds.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          commit("updateIndex", -1);
        });
      });
    },

    toggleDialog({ state, commit }, payload) {
      commit("toggleDialog", payload);
    },

    editItem({ state, commit }, payload) {
      commit("fillForm", payload);
    },

    clearForm({ state, commit }) {
      commit("clearForm");
    }
  }
};

export default module;
