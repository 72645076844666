const state = {
  color: "error",
  text: "",
  shown: false,
  timeout: -1,
  id: ""
};

const mutations = {
  setMessage(state, data) {
    state.text = data.text;
    state.color = data.color;
    state.id = data.id;
    state.shown = true;
  },
  toggleMessage(state, payload) {
    state.shown = payload;
  }
};

const actions = {
  setMessage({ commit }, data) {
    commit("setMessage", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
