import store from "@/store";
import {
  UnauthorizedError,
  EnvironmentNotFoundError,
  RequiredFieldMissingError,
  EnvironmentExistsError,
  UsernameAlreadyExistsError,
  EmailAlreadyExistsError,
  IdNotFoundError,
  PasswordValidationError,
  TwoFANotActivatedError,
  TwoFactorAuthDeactivationFailError,
  TwoFAActivatedError,
  InvalidOTP,
  TwoFANotInitiatedError
} from "./error.js";
import { ErrorCodeEnum, StatusCodeEnum } from "./enums.js";
export function environmentId() {
  return store.state.login.user.environment_id;
}

/**
 * return true/false if element's scroll reached bottom of element's content
 * @param  {Integer} scrollTop height of element's content is vertically scrolled
 * @param  {Integer} clientHeight inner height of the element's visible element content
 * @param  {Integer} scrollHeight height of the element's content with not-visible on the screen due to overflow
 */
export function isScrollBottom(scrollTop, clientHeight, scrollHeight) {
  return scrollTop + clientHeight >= scrollHeight;
}

export function getDeepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

export function sleeper(ms) {
  return function (x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}

export function periodicCall(callback, timer) {
  callback();
  return setInterval(callback, timer);
}

export function getFileNameFromResponseHeader(response) {
  return response.headers["content-disposition"]
    .split("filename=")[1]
    .split(";")[0];
}

export function commonErrorHandler(error) {
  const status = error.response.status;
  const errorCode = !isNaN(error.response.data.error)
    ? error.response.data.error
    : null;

  switch (status) {
    case StatusCodeEnum.UNAUTHORIZED:
      throw new UnauthorizedError();
    case StatusCodeEnum.NOT_FOUND:
      handleNotFoundError(errorCode);
      break;
    case StatusCodeEnum.BAD_REQUEST:
      handleBadRequestError(errorCode);
      break;
    default:
      throw new Error();
  }
}

function handleNotFoundError(errorCode) {
  switch (errorCode) {
    case ErrorCodeEnum.INVALID_TOKEN:
      throw new EnvironmentNotFoundError();
    case ErrorCodeEnum.INVALID_ID:
      throw new IdNotFoundError();
    default:
      throw new Error();
  }
}

function handleBadRequestError(errorCode) {
  switch (errorCode) {
    case ErrorCodeEnum.MISSING_VALUES:
      throw new RequiredFieldMissingError();
    case ErrorCodeEnum.ENV_NAME_EXISTS:
      throw new EnvironmentExistsError();
    case ErrorCodeEnum.EMAIL_ALREADY_EXISTS:
      throw new EmailAlreadyExistsError();
    case ErrorCodeEnum.PASSWORD_NOT_VALID:
      throw new PasswordValidationError();
    case ErrorCodeEnum.USERNAME_ALREADY_EXISTS:
      throw new UsernameAlreadyExistsError();
    case ErrorCodeEnum.TWO_FACTOR_AUTH_ALREADY_ACTIVE:
      throw new TwoFAActivatedError();
    case ErrorCodeEnum.TWO_FACTOR_AUTH_INVALID_OTP:
      throw new InvalidOTP();
    case ErrorCodeEnum.TWO_FACTOR_AUTH_NOT_INITIATED:
      throw new TwoFANotInitiatedError();
    case ErrorCodeEnum.TWO_FACTOR_AUTH_NOT_ACTIVE:
      throw new TwoFANotActivatedError();
    case ErrorCodeEnum.TWO_FACTOR_AUTH_DEACTIVATE_FAIL:
      throw new TwoFactorAuthDeactivationFailError();
    default:
      throw new Error();
  }
}

export function chunkArray(arr, size) {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }

  return result;
}
