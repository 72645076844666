import i18n from "@/plugins/i18n";

const csvHeaders = {
  environment_name: i18n.t("modules.admin.usageMonitoring.environmentName"),
  current_week: i18n.t("modules.admin.usageMonitoring.currentWeek"),
  current_month: i18n.t("modules.admin.usageMonitoring.currentMonth"),
  current_year: i18n.t("modules.admin.usageMonitoring.currentYear")
};

function convertSecondsToHour(duration) {
  let data = duration / 3600;
  if (data >= 1) {
    return Math.round(data);
  } else {
    return data.toFixed(1);
  }
}

export default {
  csvHeaders,
  convertSecondsToHour
};
