import { render, staticRenderFns } from "./configure-two-fa.vue?vue&type=template&id=cfe3a50c"
import script from "./configure-two-fa.vue?vue&type=script&lang=js"
export * from "./configure-two-fa.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports