import { auth } from "@/http";
import { UnauthorizedError, EnvironmentNotFoundError } from "@/utils/error";
import { environmentId } from "@/utils";

/**
 * Fetch Audit Log associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchAuditLog(
  params = {
    object_id: 0,
    page_size: null,
    free_text: null,
    start_date: null,
    end_date: null,
    resources: null,
    actions: null
  }
) {
  return auth
    .get("config/" + environmentId() + "/user_audits", { params })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      } else throw new Error();
    });
}

export default {
  fetchAuditLog
};
