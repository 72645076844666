import { auth } from "@/http";
import { environmentId } from "@/utils";
import {
  UnauthorizedError,
  ForbiddenError,
  InternalServerError,
  RequiredFieldMissingError
} from "@/utils/error";

function fetchSatcomMessages() {
  return auth
    .get("config/" + environmentId() + "/satcom_messages")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 403) {
        throw new ForbiddenError();
      } else if (error.response.status === 500) {
        throw new InternalServerError();
      } else throw new Error();
    });
}

function setSatcomTarget(data) {
  let formattedData = {
    satcom_target: {
      url: data.url,
      api_key: data.api_key
    }
  };
  return auth
    .put("config/" + environmentId() + "/satcom_target", formattedData)
    .catch(error => {
      if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else throw new Error();
      } else if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 403) {
        throw new ForbiddenError();
      } else throw new Error();
    });
}

function fetchSatcomTarget() {
  return auth
    .get("config/" + environmentId() + "/satcom_target")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 403) {
        throw new ForbiddenError();
      } else if (error.response.status === 500) {
        throw new InternalServerError();
      } else throw new Error();
    });
}

export default {
  fetchSatcomMessages,
  setSatcomTarget,
  fetchSatcomTarget
};
