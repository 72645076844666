const PermissionEnum = Object.freeze({
  ENVIRONMENTS: {
    VIEW: 20,
    ADD: 21,
    UPDATE: 22,
    DELETE: 23,
    UPDATE_ALL: null,
    DELETE_ALL: null
  },
  USERS: {
    VIEW: 30,
    ADD: 31,
    UPDATE: 32,
    DELETE: 33,
    UPDATE_ALL: null,
    DELETE_ALL: null
  },
  APPLICATION_RULES: {
    VIEW: 60,
    ADD: 61,
    UPDATE: 62,
    DELETE: 63,
    UPDATE_ALL: null,
    DELETE_ALL: null
  },
  DEVICES: {
    VIEW: 50,
    ADD: 51,
    UPDATE: 52,
    DELETE: 54,
    UPDATE_ALL: 53,
    DELETE_ALL: 55
  },
  OPENVPN_PROFILE: {
    VIEW: 100,
    ADD: 101,
    UPDATE: 102,
    DELETE: 103,
    UPDATE_ALL: null,
    DELETE_ALL: null
  },
  RF_RECORDINGS: {
    VIEW: 110,
    ADD: null,
    UPDATE: null,
    DELETE: null,
    UPDATE_ALL: 111,
    DELETE_ALL: null
  },
  ADMIN: {
    VIEW: 130,
    ADD: null,
    UPDATE: null,
    DELETE: null,
    UPDATE_ALL: 131,
    DELETE_ALL: 132
  },
  LOGS: {
    VIEW: 70,
    ADD: null,
    UPDATE: null,
    DELETE: null,
    UPDATE_ALL: null,
    DELETE_ALL: null
  },
  MESSAGING_LOG: {
    VIEW: 120,
    ADD: null,
    UPDATE: null,
    DELETE: null,
    UPDATE_ALL: null,
    DELETE_ALL: null
  }
});

function getAllPermissions() {
  let permissions = [];
  let permissionItems = Object.values(PermissionEnum);
  permissionItems.forEach(permission => {
    let values = Object.values(permission);
    permissions.push(...values);
  });

  // remove null values
  const allPermissions = permissions.filter(item => Number.isInteger(item));

  return allPermissions;
}

const permissionMixin = {
  data() {
    return {
      PermissionEnum
    };
  },
  computed: {
    loggedInUserPermissions() {
      let userInfo = this.$store.state.login.user;
      return userInfo.permissions;
    },
    environment_manager() {
      return this.$store.state.login.user.environment_manager;
    },
    loggedInUserDevices() {
      return this.$store.state.login.user.entities.devices;
    }
  },
  methods: {
    isPermitted(needed_permission_level) {
      if (this.environment_manager) return true;
      return this.loggedInUserPermissions.includes(needed_permission_level);
    },
    isPermittedDevice(needed_permission_level, deviceId) {
      let isUserOwnedDevice = this.loggedInUserDevices.includes(deviceId);
      if (this.isPermitted(needed_permission_level) && isUserOwnedDevice)
        return true;
      return false;
    }
  }
};

export { permissionMixin, PermissionEnum, getAllPermissions };
