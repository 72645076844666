import service from "@/modules/logs/system-log/service";

/* eslint-disable no-unused-vars */
const defaults = {
  systemLog: [],
  deviceLog: [],
  sortedDeviceLog: [],
  selected: null,
  search: "",
  dialog: false,
  nextQueryId: 0,
  previousQueryId: [],
  dialogLoading: false
};

const module = {
  namespaced: true,

  state: { ...defaults },

  getters: {
    deviceLogData(state) {
      return state.sortedDeviceLog.map(item => {
        return {
          x: new Date(item.log_time),
          y: item.value,
          severity: item.severity
        };
      });
    },

    deviceLogTimestamp(state) {
      return state.sortedDeviceLog.map(item => new Date(item.log_time));
    }
  },

  mutations: {
    load(state, data) {
      state.systemLog = data;
    },
    loadDeviceLog(state, data) {
      state.deviceLog = data;
      state.sortedDeviceLog = data.sort((a, b) =>
        a.log_time < b.log_time ? -1 : a.log_time > b.log_time ? 1 : 0
      );
    },
    updateSearch(state, payload) {
      state.search = payload;
    },
    updateSelected(state, payload) {
      state.selected = payload;
    },
    updateDialog(state, payload) {
      state.dialog = payload;
    },
    updateDialogLoading(state, payload) {
      state.dialogLoading = payload;
    },
    updateQueryId(state, payload) {
      state.nextQueryId = payload.nextQueryId;
      state.previousQueryId = payload.previousQueryId;
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }, payload) {
      return service.fetchSystemLog(payload).then(response => {
        let data = response.data.system_logs;
        commit("load", data);
        return response;
      });
    },

    getDeviceLog({ state, commit }, payload) {
      return service
        .fetchDeviceSystemLog(
          payload.id,
          payload.attributeType,
          payload.object_id,
          payload.logTime
        )
        .then(response => {
          let data = [...response.data.system_logs];
          commit("loadDeviceLog", data);
          return response;
        });
    },
    fetchToDownload(context, payload) {
      return service.fetchSystemLog(payload);
    }
  }
};

export default module;
