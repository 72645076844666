import service from "@/modules/groups/service";
import i18n from "@/plugins/i18n";

/* eslint-disable no-unused-vars */
const defaults = {
  groups: [],
  search: "",
  dialog: false,
  formTitle: i18n.t("modules.groups.add"),
  editedIndex: -1,
  editedItem: {
    name: ""
  },
  defaultItem: {
    name: ""
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    load(state, data) {
      state.groups = data;
    },
    addItem(state, data) {
      state.groups.push(data);
    },
    updateItem(state, item) {
      Object.assign(state.groups[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    fillForm(state, payload) {
      state.editedItem = Object.assign({}, payload);
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateName(state, value) {
      state.editedItem.name = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateFormTitle(state, type) {
      if (type === 1) {
        state.formTitle = i18n.t("modules.groups.add");
      } else {
        state.formTitle = i18n.t("modules.groups.edit");
      }
    },
    updateSearch(state, payload) {
      state.search = payload;
    },
    removeItem(state, id) {
      let index = state.groups.findIndex(x => x.id === id);
      state.groups.splice(index, 1);
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }) {
      return service.fetchGroups().then(response => {
        let data = response.data.groups;
        commit("load", data);
      });
    },

    add({ state, commit, dispatch }, data) {
      return service.addGroup(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.groups.addSuccess"),
              id: "add-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          /*let newData = {
            id: response.data.id,
            name: data.name
          };
          commit("addItem", newData);*/
          commit("updateIndex", -1);
        });
      });
    },

    update({ state, commit, dispatch }, data) {
      return service.updateGroup(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.groups.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          // commit("updateItem", data);
          commit("updateIndex", -1);
        });
      });
    },

    delete({ state, commit, dispatch }, id) {
      return service.deleteGroup(id).then(response => {
        dispatch("fetchAll").then(() => {
          // commit("removeItem", id);
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.groups.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
        });
      });
    },

    toggleDialog({ state, commit }, payload) {
      commit("toggleDialog", payload);
    },

    editItem({ state, commit }, payload) {
      commit("fillForm", payload);
    },

    clearForm({ state, commit }) {
      commit("clearForm");
    }
  }
};

export default module;
