import { auth } from "@/http";
import { environmentId } from "@/utils";
import { commonErrorHandler } from "@/utils";

/**
 * Fetch users associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchUsers() {
  return auth
    .get("config/" + environmentId() + "/users")
    .catch(commonErrorHandler);
}

/**
 * Adds User
 * @param {Object} data User data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {UsernameAlreadyExistsError} if username already exists
 * @throws {EmailAlreadyExistsError} if email already exits
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function addUser(data) {
  let formattedData = {
    user: {
      username: data.username,
      password: data.password,
      email: data.email,
      description: data.description,
      permissions: data.permissions,
      severity: data.severity,
      is_disabled: data.is_disabled
    }
  };
  return auth
    .post("config/" + environmentId() + "/users", formattedData)
    .catch(commonErrorHandler);
}

/**
 * Updates user
 * @param {Object} data User data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {UsernameAlreadyExistsError} if username already exists
 * @throws {EmailAlreadyExistsError} if email already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if userId not found
 * @throws {Error}
 * @returns {Promise}
 */
function updateUser(data) {
  let formattedData = {
    user: {
      username: data.username,
      password: data.password || null,
      email: data.email,
      description: data.description,
      permissions: data.permissions,
      severity: data.severity,
      is_disabled: data.is_disabled
    }
  };
  return auth
    .put("config/" + environmentId() + "/users/" + data.id, formattedData)
    .catch(commonErrorHandler);
}

/**
 * Deletes user
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function deleteUser(id) {
  return auth
    .delete("config/" + environmentId() + "/users/" + id)
    .catch(commonErrorHandler);
}
function delete2FA(id) {
  return auth
    .post(`config/${environmentId()}/users/${id}/2fa/deactivate`)
    .catch(commonErrorHandler);
}

export default {
  fetchUsers,
  addUser,
  updateUser,
  deleteUser,
  delete2FA
};
