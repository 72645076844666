<script>
import { mapFields } from "vuex-map-fields";
import ConfigureTwoFa from "./configure-two-fa.vue";

export default {
  components: { ConfigureTwoFa },

  data() {
    return {
      selectedSetting: 0,
      settingsList: [
        {
          id: "allsight-settings__nav_2fa",
          icon: "mdi-two-factor-authentication",
          text: this.$t("layouts.app.settings.configureTwoFA.title"),
          component: "configure-two-fa"
        }
      ]
    };
  },
  computed: {
    ...mapFields("appLayout", ["settings.settingsDialog"]),
    component() {
      return this.settingsList[this.selectedSetting].component;
    }
  },
  methods: {
    cancel() {
      this.settingsDialog = false;
    }
  }
};
</script>

<template>
  <v-dialog
    v-model="settingsDialog"
    persistent
    width="800"
    id="allsight-settings__dialog"
  >
    <v-card id="allsight-settings__dialog-card" rounded="lg">
      <v-app-bar
        color="primary"
        dark
        class="d-flex justify-center pa-0 ma-0"
        id="allsight-settings__dialog-card__app-bar"
      >
        <v-toolbar-title
          class="font-weight-bold pa-0 ma-0"
          id="allsight-settings__dialog-card__app-bar-title"
        >
          {{ $t("layouts.app.settings.allsightSettings") }}
        </v-toolbar-title>
      </v-app-bar>
      <v-layout>
        <v-flex xs3>
          <v-list elevation="4" height="100%" id="allsight-settings_nav">
            <v-list-item-group
              v-model="selectedSetting"
              color="primary border"
              id="allsight-settings_nav-group"
            >
              <v-list-item
                link
                v-for="(setting, index) in settingsList"
                :key="index"
                :id="setting.id"
              >
                <v-list-item-icon>
                  <v-icon :id="`${setting.id}__icon`">{{
                    setting.icon
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :id="`${setting.id}__text`">{{
                    setting.text
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-flex>
        <v-flex xs9>
          <component
            :is="component"
            @cancel="cancel"
            :id="`allsight-settings__settings-box__${component}`"
          ></component>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>
