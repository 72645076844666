import { auth } from "@/http";
import { commonErrorHandler, environmentId } from "@/utils";

function fetchServerTime() {
  return auth
    .get(`config/${environmentId()}/server_clock`)
    .catch(commonErrorHandler);
}

function fetchVersions() {
  return auth
    .get(`config/${environmentId()}/versions`)
    .catch(commonErrorHandler);
}

function setupTwoFA() {
  return auth.post(`config/${environmentId()}/2fa`).catch(commonErrorHandler);
}

function activateTwoFA(payload) {
  return auth
    .post(`config/${environmentId()}/2fa/activate`, payload)
    .catch(commonErrorHandler);
}

function deleteTwoFA(payload) {
  return auth
    .post(`config/${environmentId()}/2fa/deactivate`, payload)
    .catch(commonErrorHandler);
}

export default {
  activateTwoFA,
  deleteTwoFA,
  fetchServerTime,
  fetchVersions,
  setupTwoFA
};
