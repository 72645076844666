const IP_PATTERN = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;

function getPayloadFromData(data) {
  let formattedData = {
    name: data.name,
    pairing_id: data.pairing_id,
    type: data.type,
    is_enabled: data.isEnabled,
    is_server: data.isServer,
    upgrade_policy: data.upgradePolicy
  };

  if (data.isServer) {
    Object.assign(formattedData, {
      ip_addresses: data.ipAddresses,
      ports: {
        from: parseInt(data.fromPort),
        to: parseInt(data.toPort)
      }
    });
  }

  let payload = { device: formattedData };

  return payload;
}

export { IP_PATTERN, getPayloadFromData };
