import { http } from "@/http";
import {
  UserCredentialsError,
  UserEnvironmentError
} from "@/modules/login/util";
import { ResetTokenInvalidError } from "@/modules/reset-password/util";
import { PasswordValidationError } from "@/utils/error";

/**
 * Reset user password using given credentials.
 * @param {Object} credentials credentials object
 * @param {String} credentials.password
 * @param {String} credentials.environment
 * @throws {UserCredentialsError} if user credentials are incorrect
 * @throws {UserEnvironmentError} if user environment is incorrect
 * @throws {ResetTokenInvalidError} if the reset token used by the user is expired or is invalid
 * @throws {Error}
 * @returns {Promise}
 */
function submit(credentials) {
  return http.post("/auth/reset", credentials).catch(error => {
    console.log(error);
    if (error.response.status === 400) {
      if (error.response.data.error === 1) {
        throw new UserCredentialsError();
      } else if (error.response.data.error === 2) {
        throw new UserEnvironmentError();
      } else if (error.response.data.error == 32) {
        throw new PasswordValidationError();
      }
      throw new ResetTokenInvalidError();
    }
  });
}

/**
 * Check if the password reset token is valid.
 * @param {String} userToken password reset token
 * @returns {Promise}
 */
function checkUserToken(userToken) {
  return http.post("/auth/check_token/" + userToken);
}

export default {
  submit,
  checkUserToken
};
