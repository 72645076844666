import service from "@/modules/logs/audit-log/service";

/* eslint-disable no-unused-vars */
const defaults = {
  auditLog: [],
  loadItems: [],
  isLastPage: false,
  paginateParams: {
    object_id: 0,
    page_size: null,
    free_text: null,
    start_date: null,
    end_date: null,
    resources: [],
    actions: []
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },

  mutations: {
    load(state, data) {
      state.auditLog = data;
    },
    updatePaginateParams(state, payload) {
      state.paginateParams = payload;
    },
    updateObjectId(state, payload) {
      state.paginateParams.object_id = payload;
    },
    updateLoadItems(state, payload) {
      state.loadItems = payload;
    },
    updateIsLastPage(state, payload) {
      state.isLastPage = payload;
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }, payload) {
      return service.fetchAuditLog(payload).then(response => {
        let data = response.data.user_audits;
        commit("load", data);
      });
    },
    fetchToDownload(context, payload) {
      return service.fetchAuditLog(payload);
    },
    editObjectId({ commit }, payload) {
      commit("updateObjectId", payload);
    }
  }
};

export default module;
