import { auth } from "@/http";
import {
  UnauthorizedError,
  EnvironmentNotFoundError,
  RequiredFieldMissingError,
  IdNotFoundError,
  DeviceParametersExistsError,
  DeviceNameExistsError,
  ApplicationRuleNameAlreadyExistsError,
  ApplicationRuleAlreadyExistsError,
  VpnProfileNameAlreadyExists,
  VpnGenerateError,
  DuplicateCustomKeyError,
  NotAllowedCustomKeysError,
  NotAllowedPrivateProfileError
} from "@/utils/error";
import { environmentId } from "@/utils";
import util from "./util";
/**
 * Fetch Application Rules associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchApplicationRules() {
  return auth
    .get("config/" + environmentId() + "/application_rules")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Adds Application Rule
 * @param {Object} data Application Rule data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {DeviceNameExistsError} if application rule name already exists
 * @throws {DeviceParametersExistsError} if application rule parameters already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise<any> | Promise<T | T>}
 */
function addApplicationRule(data) {
  let formattedData = util.applicationRulePayload(data);
  return auth
    .post("config/" + environmentId() + "/application_rules", formattedData)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        }
        if (error.response.data.error === 50) {
          throw new DeviceNameExistsError();
        }
        if (error.response.data.error === 51) {
          throw new DeviceParametersExistsError();
        }
        if (error.response.data.error === 60) {
          throw new ApplicationRuleNameAlreadyExistsError();
        }
        if (error.response.data.error === 61) {
          throw new ApplicationRuleAlreadyExistsError();
        }
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Updates Application Rule
 * @param {Object} data application rule data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {DeviceNameExistsError} if application rule name already exists
 * @throws {DeviceParametersExistsError} if application rule parameters already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function updateApplicationRule(data) {
  let formattedData = util.applicationRulePayload(data);
  return auth
    .put(
      "config/" + environmentId() + "/application_rules/" + data.id,
      formattedData
    )
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        }
        if (error.response.data.error === 50) {
          throw new DeviceNameExistsError();
        }
        if (error.response.data.error === 51) {
          throw new DeviceParametersExistsError();
        }
        if (error.response.data.error === 60) {
          throw new ApplicationRuleNameAlreadyExistsError();
        }
        if (error.response.data.error === 61) {
          throw new ApplicationRuleAlreadyExistsError();
        }
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
        if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Deletes Application Rule
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 * @returns {Promise}
 */
function deleteApplicationRule(id) {
  return auth
    .delete("config/" + environmentId() + "/application_rules/" + id)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
        if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Generates VPN Certificates
 * @param  {Object} data VPN Certification Data
 * @throws {UnauthorizedError} if permission denied
 */
function generateVpnCertification(data) {
  return auth
    .post("config/" + environmentId() + "/vpn_certificates", data, {
      responseType: "blob"
    })
    .catch(error => {
      if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        }
        if (error.response.data.error === 92) {
          throw new NotAllowedPrivateProfileError();
        }
      }
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 500) {
        throw new VpnGenerateError();
      }
      throw new Error();
    });
}

/** Create VPN Profile
 * @param  {Object} data VPN Profile Data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 */
function createVpnProfile(data) {
  let formattedData = util.vpnProfilePayload(data);
  return auth
    .post("config/" + environmentId() + "/apps/openvpn/profiles", formattedData)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        }
        if (error.response.data.error === 80) {
          throw new VpnProfileNameAlreadyExists();
        }
        if (error.response.data.error === 82) {
          throw new DuplicateCustomKeyError();
        }
        if (error.response.data.error === 83) {
          throw new NotAllowedCustomKeysError();
        }
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Fetch VPN Profile associated with environmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchVpnProfiles() {
  return auth
    .get("config/" + environmentId() + "/apps/openvpn/profiles")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Updates VPN Profile
 * @param  {Object} data VPN Profile Data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {Error}
 */
function updateVpnProfile(data) {
  let formattedData = util.vpnProfilePayload(data);
  return auth
    .put(
      "config/" + environmentId() + "/apps/openvpn/profiles/" + data.id,
      formattedData
    )
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        }
        if (error.response.data.error === 80) {
          throw new VpnProfileNameAlreadyExists();
        }
        if (error.response.data.error === 82) {
          throw new DuplicateCustomKeyError();
        }
        if (error.response.data.error === 83) {
          throw new NotAllowedCustomKeysError();
        }
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
      }
      throw new Error();
    });
}

/**
 * Deletes VPN Profile
 * @param {Number} id
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if environmentId not found
 * @throws {IdNotFoundError} if id not found
 * @throws {Error}
 */
function deleteVpnProfile(id) {
  return auth
    .delete("config/" + environmentId() + "/apps/openvpn/profiles/" + id)
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      }
      if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        }
        if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      }
      throw new Error();
    });
}

export default {
  fetchApplicationRules,
  addApplicationRule,
  updateApplicationRule,
  deleteApplicationRule,
  generateVpnCertification,
  createVpnProfile,
  fetchVpnProfiles,
  updateVpnProfile,
  deleteVpnProfile
};
