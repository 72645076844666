import service from "./service";
import i18n from "@/plugins/i18n";
import { getField, updateField } from "vuex-map-fields";

/* eslint-disable no-unused-vars */
const defaults = {
  applicationRules: [],
  search: "",
  dialog: false,
  formTitle: i18n.t("modules.dataPath.add"),
  editedIndex: -1,
  editedItem: {
    name: null,
    vpnProfileId: null,
    isEnabled: true,
    hostRedundancyLevel: 0,
    hostRedundancyPercentage: null,
    hubRedundancyLevel: 0,
    hubRedundancyPercentage: null,
    hostReorder: 0,
    hubReorder: 0,
    hostPort: null,
    hosts: [],
    hubs: [],
    hubPort: null,
    hubIP: null,
    primaryHub: null,
    secondaryHub: null,
    tertiaryHub: null
  },
  defaultItem: {
    name: null,
    vpnProfileId: null,
    isEnabled: true,
    hostRedundancyLevel: 0,
    hostRedundancyPercentage: null,
    hubRedundancyLevel: 0,
    hubRedundancyPercentage: null,
    hostReorder: 0,
    hubReorder: 0,
    hostPort: null,
    hosts: [],
    hubs: [],
    hubPort: null,
    hubIP: null,
    primaryHub: null,
    secondaryHub: null,
    tertiaryHub: null
  },
  vpn: {
    dialog: false,
    certificationType: 1,
    certificateCN: null,
    profileId: null
  },
  vpnProfile: {
    vpnProfiles: [],
    profilesVisible: true,
    vpnProfileDialog: false,
    advancedDialog: false,
    moreOptions: false,
    search: "",
    formTitle: i18n.t("modules.dataPath.vpn.createNew"),
    editedIndex: -1,
    editedItem: {
      name: null,
      is_private: false,
      encryption_type: 3,
      compression_type: 3,
      layer_type: 2,
      ip_address: null,
      port: null,
      subnet_mask: null,
      auto_activate: false,
      server_ip_address: "",
      isEnabled: true,
      fragment: null,
      mssfix: null,
      auth: null,
      key_direction: null,
      remote_cert_tls: null,
      tls_auth: null,
      type: null,
      username: null,
      password: null,
      custom_fields: [
        {
          key_name: null,
          key_value: null
        }
      ]
    },
    defaultItem: {
      name: null,
      is_private: false,
      encryption_type: 3,
      compression_type: 3,
      layer_type: 2,
      ip_address: null,
      port: null,
      subnet_mask: null,
      auto_activate: false,
      server_ip_address: "",
      isEnabled: true,
      fragment: null,
      mssfix: null,
      auth: null,
      key_direction: null,
      remote_cert_tls: null,
      tls_auth: null,
      type: null,
      username: null,
      password: null,
      custom_fields: [
        {
          key_name: null,
          key_value: null
        }
      ]
    }
  }
};

const module = {
  namespaced: true,

  state: { ...defaults },
  getters: {
    getField
  },
  mutations: {
    updateField,
    load(state, data) {
      state.applicationRules = data;
    },
    addItem(state, data) {
      state.applicationRules.push(data);
    },
    updateItem(state, item) {
      Object.assign(state.applicationRules[state.editedIndex], item);
    },
    toggleDialog(state, payload) {
      state.dialog = payload;
    },
    fillForm(state, payload) {
      let item = state.editedItem;
      item.hosts = Object.keys(payload.hosts_list);
      item.name = payload.name;
      if (payload.profile_id) {
        item.vpnProfileId = payload.profile_id;
      } else {
        item.vpnProfileId = "No VPN/Custom";
        if (payload.host_endpoint_info && payload.hub_endpoint_info) {
          item.hostPort = payload.host_endpoint_info.port;
          item.hubPort = payload.hub_endpoint_info.port;
          item.hubIP = payload.hub_endpoint_info.destination_ip;
        }
      }
      item.isEnabled = payload.is_enabled;
      item.hostRedundancyLevel = payload.hosts_settings.redundancy_level;
      item.hostRedundancyPercentage =
        payload.hosts_settings.redundancy_percentage;
      item.hostReorder = payload.hosts_settings.reorder;
      item.hubRedundancyLevel = payload.hubs_settings.redundancy_level;
      item.hubRedundancyPercentage =
        payload.hubs_settings.redundancy_percentage;
      item.hubReorder = payload.hubs_settings.reorder;
      item.primaryHub = payload.hubs_list[0];
      item.secondaryHub = payload.hubs_list[1];
      item.tertiaryHub = payload.hubs_list[2];
    },
    clearForm(state) {
      state.editedItem = Object.assign({}, state.defaultItem);
    },
    updateName(state, value) {
      state.editedItem.name = value;
    },
    updateVpnProfileId(state, value) {
      state.editedItem.vpnProfileId = value;
    },
    updateIsEnabled(state, value) {
      state.editedItem.isEnabled = value;
    },
    updateHostRedundancyLevel(state, value) {
      state.editedItem.hostRedundancyLevel = value;
    },
    updateHubRedundancyLevel(state, value) {
      state.editedItem.hubRedundancyLevel = value;
    },

    updateHostRedundancyPercentage(state, value) {
      state.editedItem.hostRedundancyPercentage = value;
    },
    updateHubRedundancyPercentage(state, value) {
      state.editedItem.hubRedundancyPercentage = value;
    },
    updateHostReorder(state, value) {
      state.editedItem.hostReorder = value;
    },
    updateHubReorder(state, value) {
      state.editedItem.hubReorder = value;
    },
    updateHostPort(state, value) {
      state.editedItem.hostPort = value;
    },
    updateHubPort(state, value) {
      state.editedItem.hubPort = value;
    },
    updateHubIP(state, value) {
      state.editedItem.hubIP = value;
    },
    updatePrimaryHub(state, value) {
      state.editedItem.primaryHub = value;
    },
    updateSecondaryHub(state, value) {
      state.editedItem.secondaryHub = value;
    },
    updateTertiaryHub(state, value) {
      state.editedItem.tertiaryHub = value;
    },
    updateHosts(state, value) {
      state.editedItem.hosts = value;
    },
    updateHubs(state, value) {
      state.editedItem.hubs = value;
    },
    updateIndex(state, payload) {
      state.editedIndex = payload;
    },
    updateFormTitle(state, type) {
      if (type === 1) {
        state.formTitle = i18n.t("modules.dataPath.add");
      } else {
        state.formTitle = i18n.t("modules.dataPath.edit");
      }
    },
    updateSearch(state, payload) {
      state.search = payload;
    },
    removeItem(state, id) {
      let index = state.applicationRules.findIndex(x => x.id === id);
      state.applicationRules.splice(index, 1);
    },
    toggleVpnDialog(state, payload) {
      state.vpn.dialog = payload;
    },
    updateCertificationType(state, payload) {
      state.vpn.certificationType = payload;
    },
    updateCertificateCN(state, payload) {
      state.vpn.certificateCN = payload;
    },
    updateProfileId(state, payload) {
      state.vpn.profileId = payload;
    },
    clearVpnForm(state) {
      Object.keys(defaults.vpn).forEach(key => {
        state.vpn[key] = defaults.vpn[key];
      });
    },
    toggleVpnProfilesVisibility(state, payload) {
      state.vpnProfile.profilesVisible = payload;
    },
    toggleVpnProfileDialog(state, payload) {
      state.vpnProfile.vpnProfileDialog = payload;
    },
    updateVpnProfileName(state, value) {
      state.vpnProfile.editedItem.name = value;
    },
    updateVpnIsEnabled(state, value) {
      state.vpnProfile.editedItem.isEnabled = value;
    },
    updateIsPrivate(state, value) {
      state.vpnProfile.editedItem.is_private = value;
    },
    updateEncryptionType(state, value) {
      state.vpnProfile.editedItem.encryption_type = value;
    },
    updateCompressionType(state, value) {
      state.vpnProfile.editedItem.compression_type = value;
    },
    updateLayerType(state, value) {
      state.vpnProfile.editedItem.layer_type = value;
    },
    updateIpAddress(state, value) {
      state.vpnProfile.editedItem.ip_address = value;
    },
    updatePort(state, value) {
      state.vpnProfile.editedItem.port = value;
    },
    updateSubnetMask(state, value) {
      state.vpnProfile.editedItem.subnet_mask = value;
    },
    updateServerIpAddress(state, value) {
      state.vpnProfile.editedItem.server_ip_address = value;
    },
    updateFragment(state, value) {
      state.vpnProfile.editedItem.fragment = value;
    },
    updateMssfix(state, value) {
      state.vpnProfile.editedItem.mssfix = value;
    },
    updateAuth(state, value) {
      state.vpnProfile.editedItem.auth = value;
    },
    updateKeyDirection(state, value) {
      state.vpnProfile.editedItem.key_direction = value;
    },
    updateRemoteCertTls(state, value) {
      state.vpnProfile.editedItem.remote_cert_tls = value;
    },
    updateTlsAuth(state, value) {
      state.vpnProfile.editedItem.tls_auth = value;
    },
    updateType(state, value) {
      state.vpnProfile.editedItem.type = value;
    },
    updateUsername(state, value) {
      state.vpnProfile.editedItem.username = value;
    },
    updatePassword(state, value) {
      state.vpnProfile.editedItem.password = value;
    },
    updateCustomFields(state, value) {
      state.vpnProfile.editedItem.custom_fields = value;
    },
    addCustomFieldRow(state) {
      state.vpnProfile.editedItem.custom_fields.push({
        key_name: "",
        key_value: ""
      });
    },
    removeCustomFieldRow(state, index) {
      state.vpnProfile.editedItem.custom_fields.splice(index, 1);
    },
    loadVpnProfile(state, data) {
      state.vpnProfile.vpnProfiles = data;
    },
    updateVpnProfileSearch(state, payload) {
      state.vpnProfile.search = payload;
    },
    toggleAdvancedDialog(state, payload) {
      state.vpnProfile.advancedDialog = payload;
    },
    toggleMoreOptionsDialog(state, payload) {
      state.vpnProfile.moreOptions = payload;
    },
    updateVpnProfileFormTitle(state, type) {
      if (type === 1) {
        state.vpnProfile.formTitle = i18n.t("modules.dataPath.vpn.createNew");
      } else {
        state.vpnProfile.formTitle = i18n.t("modules.dataPath.vpn.edit");
      }
    },
    fillVpnProfileForm(state, payload) {
      let item = state.vpnProfile.editedItem;
      item.name = payload.name;
      item.is_private = payload.is_private;
      item.encryption_type = payload.general_config.encryption_type;
      item.compression_type = payload.general_config.compression_type;
      item.layer_type = payload.general_config.layer_type;
      item.server_ip_address = payload.server_config.server_address;
      item.port = payload.server_config.port;
      if (payload.server_config.managed_server_address_info) {
        item.subnet_mask =
          payload.server_config.managed_server_address_info.subnet_mask;
        item.ip_address =
          payload.server_config.managed_server_address_info.ip_address;
      }
      item.isEnabled = payload.is_enabled;
      if (payload.advanced_config) {
        if ("custom_fields" in payload.advanced_config) {
          item.custom_fields = JSON.parse(
            JSON.stringify(payload.advanced_config.custom_fields)
          );
        }
        if ("auth_user_pass" in payload.advanced_config) {
          item.type = payload.advanced_config.auth_user_pass.type;
          item.username = payload.advanced_config.auth_user_pass.username;
          item.password = payload.advanced_config.auth_user_pass.password;
        }
        Object.keys(payload.advanced_config).every(key => {
          if (key) {
            item.fragment = payload.advanced_config.fragment;
            item.mssfix = payload.advanced_config.mssfix;
            item.auth = payload.advanced_config.auth;
            item.key_direction = payload.advanced_config.key_direction;
            item.remote_cert_tls = payload.advanced_config.remote_cert_tls;
            item.tls_auth = payload.advanced_config.tls_auth;
          }
        });
      }
    },
    clearVpnProfileForm(state) {
      state.vpnProfile.editedItem = Object.assign(
        {},
        state.vpnProfile.defaultItem
      );
      state.vpnProfile.editedItem.custom_fields = [
        {
          key_name: null,
          key_value: null
        }
      ];
    },
    updateVpnProfileIndex(state, payload) {
      state.vpnProfile.editedIndex = payload;
    },
    clearVpnAdvancedForm(state) {
      state.vpnProfile.editedItem.ip_address =
        state.vpnProfile.defaultItem.ip_address;
      state.vpnProfile.editedItem.subnet_mask =
        state.vpnProfile.defaultItem.subnet_mask;
    }
  },

  actions: {
    fetchAll({ state, commit, dispatch }) {
      return service.fetchApplicationRules().then(response => {
        let data = response.data.application_rules;
        commit("load", data);
      });
    },

    add({ state, commit, dispatch }, data) {
      data.hostsList = state.editedItem.hosts;
      return service.addApplicationRule(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.dataPath.addSuccess"),
              id: "add-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          /*let data = response.data;
          let newData = data.application_rule;
          commit("addItem", newData);*/
          commit("updateIndex", -1);
        });
      });
    },

    update({ state, commit, dispatch }, data) {
      data.hostsList = state.editedItem.hosts;
      return service.updateApplicationRule(data).then(response => {
        dispatch("fetchAll").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.dataPath.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
          dispatch("toggleDialog", false);
          dispatch("clearForm");
          // commit("updateItem", response.data.application_rule);
          commit("updateIndex", -1);
        });
      });
    },

    delete({ state, commit, dispatch }, id) {
      return service.deleteApplicationRule(id).then(response => {
        dispatch("fetchAll").then(() => {
          // commit("removeItem", id);
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.dataPath.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
        });
      });
    },

    toggleDialog({ state, commit }, payload) {
      commit("toggleDialog", payload);
    },

    editItem({ state, commit }, payload) {
      commit("fillForm", payload);
    },

    clearForm({ state, commit }) {
      commit("clearForm");
    },
    toggleVpnDialog({ commit }, payload) {
      commit("toggleVpnDialog", payload);
    },
    generateVpnCert({ commit, dispatch }, payload) {
      return service.generateVpnCertification(payload).then(response => {
        dispatch(
          "snackbar/setMessage",
          {
            color: "success",
            text: i18n.t("modules.dataPath.vpn.generateSuccess"),
            id: "add-success"
          },
          { root: true }
        );
        commit("clearVpnForm");
        dispatch("toggleVpnDialog", false);
        return response;
      });
    },
    toggleVpnProfilesVisibility({ commit }, payload) {
      commit("toggleVpnProfilesVisibility", payload);
    },
    toggleVpnProfileDialog({ commit }, payload) {
      commit("toggleVpnProfileDialog", payload);
    },
    fetchVpnProfile({ state, commit, dispatch }) {
      return service.fetchVpnProfiles().then(response => {
        let data = response.data.openvpn_profiles;
        commit("loadVpnProfile", data);
      });
    },
    createVpnProfile({ commit, dispatch }, data) {
      return service.createVpnProfile(data).then(response => {
        dispatch("fetchVpnProfile").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.dataPath.vpn.createSuccess"),
              id: "add-success"
            },
            { root: true }
          );
          commit("clearVpnProfileForm");
          dispatch("toggleVpnProfileDialog", false);
          commit("updateVpnProfileIndex", -1);
          return response;
        });
      });
    },
    toggleAdvancedDialog({ commit }, payload) {
      commit("toggleAdvancedDialog", payload);
    },
    editVpnProfile({ state, commit }, payload) {
      commit("fillVpnProfileForm", payload);
    },
    clearVpnProfileForm({ state, commit }) {
      commit("clearVpnProfileForm");
    },
    updateVpnProfile({ state, commit, dispatch }, data) {
      return service.updateVpnProfile(data).then(response => {
        dispatch("fetchVpnProfile").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.dataPath.vpn.updateSuccess"),
              id: "update-success"
            },
            { root: true }
          );
          dispatch("toggleVpnProfileDialog", false);
          dispatch("clearVpnProfileForm");
          commit("updateVpnProfileIndex", -1);
        });
      });
    },
    deleteVpnProfile({ state, commit, dispatch }, id) {
      return service.deleteVpnProfile(id).then(response => {
        dispatch("fetchVpnProfile").then(() => {
          dispatch(
            "snackbar/setMessage",
            {
              color: "success",
              text: i18n.t("modules.dataPath.vpn.deleteSuccess"),
              id: "delete-success"
            },
            { root: true }
          );
          dispatch("fetchAll");
        });
      });
    },
    toggleMoreOptionsDialog({ commit }, payload) {
      commit("toggleMoreOptionsDialog", payload);
    }
  }
};

export default module;
