<template>
  <v-menu offset-y bottom open-on-hover>
    <template v-slot:activator="{ on }">
      <v-btn :disabled="disabled" :text="text" :icon="icon" v-on="on">
        <div>
          <span
            class="flag-icon"
            :class="getFlagIcon(locale.countryCode)"
          ></span>
          {{ "(" + locale.languageCode + ")" }}
        </div>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in locales"
        :key="item.code"
        :title="getLocaleHint(item)"
        @click="changeLocale(item.code)"
      >
        <v-list-item-avatar>
          <span class="flag-icon" :class="getFlagIcon(item.countryCode)"></span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase">
            {{ item.languageCode }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import * as LocaleCodes from "locale-codes";

const REG = /^([a-z]{2})-([A-Z]{2})$/;

function getCountryCode(code) {
  var match = code.match(REG);
  if (!match || match.length < 2) return "";
  return match[2];
}

function validate(code) {
  if (!LocaleCodes.getByTag(code)) {
    console.error(
      "[Vue warn]: Invalid locale code: " +
        code +
        "\nhttps://www.npmjs.com/package/locale-codes for further information"
    );
    return false;
  } else {
    return true;
  }
}

/**
 * Language switcher between possible locales.
 */
export default {
  name: "v-language-switcher",

  model: {
    prop: "modelLocale",
    event: "change"
  },

  props: {
    initialLocales: {
      type: Array,
      required: true,
      validator: function(value) {
        return value.every(code => validate(code));
      }
    },
    modelLocale: {
      type: String,
      validator: function(value) {
        return validate(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: Boolean,
    icon: Boolean
  },

  data() {
    return {
      /**
       * Current used locale.
       */
      locale: null
    };
  },

  computed: {
    /**
     * Array of available locale objects.
     */
    locales: function() {
      return this.initialLocales.map(code => {
        return {
          code,
          languageCode: LocaleCodes.getByTag(code)["iso639-1"],
          languageName: LocaleCodes.getByTag(code).name,
          languageNativeName: LocaleCodes.getByTag(code).local,
          countryCode: getCountryCode(code)
        };
      });
    }
  },

  watch: {
    modelLocale: function(value) {
      this.changeLocale(value);
    }
  },

  methods: {
    changeLocale(code) {
      this.locale = this.locales.find(locale => locale.code == code);
      localStorage.setItem("lang", code);
      this.$emit("change", code);
    },
    getFlagIcon(countryCode) {
      return "flag-icon-" + countryCode.toLowerCase();
    },
    getLocaleHint(locale) {
      return locale.languageName + " (" + locale.languageNativeName + ")";
    }
  },

  created() {
    this.changeLocale(this.modelLocale);
  }
};
</script>

<style scoped>
.flag-icon {
  font-size: 17px;
}
</style>
