import { http, auth } from "@/http";
import {
  IncorrectUsernamePasswordError,
  RequiredFieldMissingError,
  TemporaryPasswordMessageError,
  LoginFailedError
} from "@/utils/error";

/**
 * Logging into the system using given credentials.
 * @param {Object} credentials credentials object
 * @param {String} credentials.username
 * @param {String} credentials.password
 * @param {String} credentials.environment
 * @throws {RequiredFieldMissingError} if user credentials are missing required JSON values
 * @throws {IncorrectUsernamePasswordError} if username or password is incorrect
 * @throws {Error}
 * @returns {Promise}
 */
function login(credentials) {
  return http.post("/auth/login", credentials).catch(error => {
    if (error.response.status === 400) {
      if (error.response.data.error === 1) {
        throw new RequiredFieldMissingError();
      } else if (error.response.data.error === 10) {
        const remainingAttempts = error.response.data.fields
          ? error.response.data.fields.remaining_attempts
          : null;
        throw new IncorrectUsernamePasswordError(remainingAttempts);
      } else if (error.response.data.error === 33) {
        throw new TemporaryPasswordMessageError(error.response.data.message);
      }
    } else {
      throw new LoginFailedError();
    }
  });
}

/**
 * Logging out from the system.
 */
function logout() {
  return auth.post("/auth/logout");
}

export default {
  login,
  logout
};
