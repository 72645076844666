import { auth } from "@/http";
import {
  UnauthorizedError,
  EnvironmentNotFoundError,
  RequiredFieldMissingError,
  EnvironmentExistsError,
  UsernameAlreadyExistsError,
  EmailAlreadyExistsError,
  IdNotFoundError
} from "@/utils/error";
import { parentEnvironmentId } from "./util";

/**
 * Fetch thresholds associated with parentEnvironmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchThresholds() {
  return auth
    .get("config/" + parentEnvironmentId() + "/thresholds")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Fetch thresholds UI info associated with parentEnvironmentId
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function fetchThresholdsMetadata() {
  return auth
    .get("config/" + parentEnvironmentId() + "/thresholds_metadata")
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else throw new Error();
      } else throw new Error();
    });
}

/**
 * Updates threshold
 * @param {Object} data Threshold data
 * @throws {UnauthorizedError} if permission denied
 * @throws {RequiredFieldMissingError} if required field is missing on JSON
 * @throws {EnvironmentExistsError} if environment already exists
 * @throws {EmailAlreadyExistsError} if email already exists
 * @throws {UsernameAlreadyExistsError} if username already exists
 * @throws {UnauthorizedError} if permission denied
 * @throws {EnvironmentNotFoundError} if parentEnvironmentId not found
 * @throws {IdNotFoundError} if parentEnvironmentId not found
 * @throws {Error}
 * @returns {Promise}
 */
function updateThreshold(data) {
  let formattedData = {
    device_attribute_type: data.device_attribute_type,
    threshold_values: data.threshold_values,
    should_alert: data.should_alert
  };
  return auth
    .put("config/" + parentEnvironmentId() + "/thresholds/" + data.id, {
      threshold: formattedData
    })
    .catch(error => {
      if (error.response.status === 401) {
        throw new UnauthorizedError();
      } else if (error.response.status === 400) {
        if (error.response.data.error === 1) {
          throw new RequiredFieldMissingError();
        } else if (error.response.data.error === 20) {
          throw new EnvironmentExistsError();
        } else if (error.response.data.error === 31) {
          throw new EmailAlreadyExistsError();
        } else if (error.response.data.error === 30) {
          throw new UsernameAlreadyExistsError();
        }
      } else if (error.response.status === 404) {
        if (error.response.data.error === 5) {
          throw new EnvironmentNotFoundError();
        } else if (error.response.data.error === 6) {
          throw new IdNotFoundError();
        }
      } else throw new Error();
    });
}

export default {
  fetchThresholds,
  fetchThresholdsMetadata,
  updateThreshold
};
