import service from "@/modules/forgot-password/service";

const module = {
  namespaced: true,

  actions: {
    forgot_password(_, credentials) {
      return service.forgot_password(credentials);
    }
  }
};

export default module;
