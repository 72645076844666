<script>
import QRCode from "qrcode";
import { mapFields } from "vuex-map-fields";
import { handleError } from "@/utils/error";
import util from "@/layouts/app/util";

export default {
  data() {
    return {
      otp: "",
      authenticators: [
        {
          title: this.$t(
            "layouts.app.settings.configureTwoFA.googleAuthenticator"
          ),
          androidLink:
            "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
          iosLink:
            "https://apps.apple.com/us/app/google-authenticator/id388497605"
        }
      ],
      util
    };
  },
  computed: {
    ...mapFields("appLayout", [
      "settings.configureTwoFA.qr",
      "settings.configureTwoFA.step",
      "settings.configureTwoFA.setupStatus",
      "settings.configureTwoFA.otpUri"
    ])
  },
  watch: {
    step(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.$refs.activateOtpInput?.resetValidation();
      this.$refs.deactivateOtpInput?.resetValidation();
    }
  },
  methods: {
    generateQR(text) {
      QRCode.toDataURL(text).then(data => {
        this.qr = data;
      });
    },
    activateTwoFA() {
      if (!this.$refs.activateOtpInput.validate()) {
        return;
      }
      this.$store
        .dispatch("appLayout/activateTwoFA", { otp_code: this.otp })
        .then(() => {
          this.$store.dispatch("snackbar/setMessage", {
            color: "success",
            text: this.$t(
              "layouts.app.settings.configureTwoFA.activatedSuccess"
            ),
            id: "two-fa-activate-success"
          });
          this.step = this.setupStatus;
          this.$store.commit("login/updateTwoFAStatus", this.setupStatus);
          this.cancel();
        })
        .catch(handleError)
        .finally(() => (this.otp = ""));
    },
    setupTwoFA() {
      this.$store
        .dispatch("appLayout/setupTwoFA")
        .then(() => {
          this.qr = this.generateQR(this.otpUri);
          this.step = this.setupStatus;
        })
        .catch(handleError)
        .finally(() => {
          this.otp = "";
          this.$refs.activateOtpInput?.resetValidation();
          this.$refs.deactivateOtpInput?.resetValidation();
          this.$store.commit("login/updateTwoFAStatus", this.setupStatus);
        });
    },
    deleteTwoFA() {
      if (!this.$refs.deactivateOtpInput.validate()) {
        return;
      }
      this.$store
        .dispatch("appLayout/deleteTwoFA", { otp_code: this.otp })
        .then(() => {
          this.$store.dispatch("snackbar/setMessage", {
            color: "success",
            text: this.$t(
              "layouts.app.settings.configureTwoFA.deactivatedSuccess"
            ),
            id: "two-fa-deactivate-success"
          });

          this.step = 1;
          this.setupStatus = 1;
          this.$store.commit("login/updateTwoFAStatus", this.setupStatus);
          this.cancel();
        })
        .catch(handleError);
    },
    cancel() {
      this.otp = "";
      this.$refs.activateOtpInput?.resetValidation();
      this.$refs.deactivateOtpInput?.resetValidation();
      this.$emit("cancel");
    }
  }
};
</script>

<template>
  <v-card
    class="ma-2 text-justify d-flex flex-column"
    flat
    min-height="350"
    id="allsight-settings_configure-2fa-card"
  >
    <v-card-text
      class="pa-2"
      v-if="step === 1"
      id="allsight-settings_configure-2fa-initial"
    >
      <p
        v-for="(text, index) in $t(
          'layouts.app.settings.configureTwoFA.initialStepContents'
        )"
        :key="index"
        :id="`allsight-settings_configure-2fa-initial__paragraph-${index}`"
      >
        {{ text }}
      </p>
    </v-card-text>
    <v-card-text
      class="pa-2"
      v-if="step === 2"
      id="allsight-settings_configure-2fa-activate"
    >
      <p id="allsight-settings_configure-2fa-activate__paragraph-1">
        {{ $t("layouts.app.settings.configureTwoFA.secondStepContents[0]") }}
      </p>
      <div
        class="mb-4"
        id="allsight-settings_configure-2fa-activate__paragraph-1__authenticators"
      >
        {{ $t("layouts.app.settings.configureTwoFA.secondStepContents[1]") }}

        <ul>
          <li v-for="(authenticator, index) in authenticators" :key="index">
            {{ authenticator.title }}
            (
            <a :href="authenticator.androidLink" target="_blank">
              {{ $t("layouts.app.settings.configureTwoFA.android") }}
            </a>
            |
            <a :href="authenticator.iosLink" target="_blank">
              {{ $t("layouts.app.settings.configureTwoFA.ios") }}
            </a>
            )
          </li>
        </ul>
        <v-alert class="mt-2" color="warning" icon="mdi-alert" text>
          {{
            $t(
              "layouts.app.settings.configureTwoFA.microsoftAuthenticatorNotSupported"
            )
          }}
        </v-alert>
      </div>
      <p
        class="mb-0 pb-0"
        id="allsight-settings_configure-2fa-activate__paragraph-2"
      >
        {{ $t("layouts.app.settings.configureTwoFA.secondStepContents[2]") }}
      </p>
      <v-layout column align-center justify-center class="ma-0 pa-0">
        <v-flex xs3 class="ma-0 pa-0">
          <img
            :src="qr"
            width="160"
            class="ma-0 pa-0"
            id="allsight-settings_configure-2fa-activate__qr-image"
          />
        </v-flex>
        <p id="allsight-settings_configure-2fa-activate__paragraph-3">
          {{ $t("layouts.app.settings.configureTwoFA.secondStepContents[3]") }}
        </p>
        <div style="max-width: 350px">
          <v-otp-input
            v-model="otp"
            length="7"
            type="number"
            @finish="activateTwoFA"
            ref="activateOtpInput"
            id="allsight-settings_configure-2fa-activate__otp-input"
            @keydown="util.allowOnlyNumbers($event)"
          ></v-otp-input>
        </div>
      </v-layout>
    </v-card-text>

    <v-card-text
      class="pa-2"
      v-if="step === 3"
      id="allsight-settings_configure-2fa-deactivate"
    >
      <p
        v-for="(text, index) in $t(
          'layouts.app.settings.configureTwoFA.thirdStepContents'
        )"
        :key="index"
        :id="`allsight-settings_configure-2fa-deactivate__paragraph-${index}`"
      >
        {{ text }}
      </p>
      <v-layout justify-center align-center>
        <div style="max-width: 350px">
          <v-otp-input
            v-model="otp"
            length="7"
            type="number"
            ref="deactivateOtpInput"
            validate-on="submit"
            :rules="[v => v.length === 7 || '']"
            @keydown="util.allowOnlyNumbers($event)"
            id="allsight-settings_configure-2fa-deactivate__otp-input"
          ></v-otp-input>
        </div>
      </v-layout>
    </v-card-text>

    <v-card-actions class="mt-auto" id="allsight-settings__settings-actions">
      <v-btn @click="cancel" id="allsight-settings__cancel-btn">{{
        $t("actions.cancel")
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        v-if="step === 1"
        @click="setupTwoFA"
        id="allsight-settings_configure-2fa-initial__generate-btn"
      >
        {{ $t("layouts.app.settings.configureTwoFA.generateTwoFA") }}
      </v-btn>
      <v-btn
        color="primary"
        v-if="step === 3"
        @click="deleteTwoFA"
        id="allsight-settings_configure-2fa-deactivate__delete-btn"
      >
        {{ $t("layouts.app.settings.configureTwoFA.deleteTwoFA") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
