const CLIENT = 1;
const SERVER = 2;
const IP_PATTERN = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/gm;
const IP_REGEX = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
const SUBNET_REGEX = /^((128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(((0|128|192|224|240|248|252|254)\.0\.0)|(255\.(((0|128|192|224|240|248|252|254)\.0)|255\.(0|128|192|224|240|248|252|254)))))$/;

function downloadBlobFile(content, fileName, contentType = "application/gzip") {
  const url = window.URL.createObjectURL(
    new Blob([content], { type: contentType })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}
function applicationRulePayload(data) {
  let hubsList = [data.primaryHub];

  if (data.secondaryHub != null) {
    hubsList.push(data.secondaryHub);
  }

  if (data.tertiaryHub != null) {
    hubsList.push(data.tertiaryHub);
  }
  let formattedData = {
    application_rule: {
      name: data.name,
      profile_id: null,
      host_endpoint_info: {
        port: data.hostPort
      },
      hosts_list: data.hostsList,
      hosts_settings: {
        redundancy_level: data.hostRedundancyLevel,
        redundancy_percentage:
          data.hostRedundancyLevel === 4
            ? parseInt(data.hostRedundancyPercentage)
            : null,
        reorder: data.hostReorder
      },
      hubs_list: hubsList,
      hub_endpoint_info: {
        destination_ip: data.hubIP,
        port: data.hubPort
      },
      hubs_settings: {
        redundancy_level: data.hubRedundancyLevel,
        redundancy_percentage:
          data.hubRedundancyLevel === 4
            ? parseInt(data.hubRedundancyPercentage)
            : null,
        reorder: data.hubReorder
      },
      is_enabled: data.isEnabled
    }
  };
  if (data.vpnProfileId !== "No VPN/Custom") {
    formattedData.application_rule.profile_id = data.vpnProfileId;
    delete formattedData.application_rule.host_endpoint_info;
    delete formattedData.application_rule.hub_endpoint_info;
  }
  return formattedData;
}

function vpnProfilePayload(data) {
  let formattedData = {
    openvpn_profile: {
      name: data.name,
      is_enabled: data.is_enabled,
      is_private: data.is_private,
      general_config: {
        encryption_type: data.encryption_type,
        compression_type: data.compression_type,
        layer_type: data.layer_type
      },
      server_config: {
        managed_server_address_info: {
          ip_address: data.ip_address,
          subnet_mask: data.subnet_mask
        },
        server_address: data.server_address,
        port: data.port
      },
      advanced_config: {
        fragment: data.fragment || null,
        mssfix: data.mssfix || null,
        auth: data.auth || null,
        key_direction:
          data.key_direction === undefined ? null : data.key_direction,
        remote_cert_tls: data.remote_cert_tls || null,
        tls_auth: data.tls_auth || null,
        auth_user_pass: {
          type: data.type,
          username: data.username || null,
          password: data.password
        },
        custom_fields: data.custom_fields || null
      }
    }
  };
  let is_custom_fields = formattedData.openvpn_profile.advanced_config.custom_fields.every(
    custom_field =>
      !custom_field.key_name && !custom_field.key_value ? true : false
  );
  if (is_custom_fields) {
    formattedData.openvpn_profile.advanced_config.custom_fields = null;
  }

  let is_auth_user_pass_null = isNullValue(
    formattedData.openvpn_profile.advanced_config.auth_user_pass
  );
  if (is_auth_user_pass_null) {
    formattedData.openvpn_profile.advanced_config.auth_user_pass = null;

    let is_advanced_config_empty = isNullValue(
      formattedData.openvpn_profile.advanced_config
    );
    if (is_advanced_config_empty) {
      formattedData.openvpn_profile.advanced_config = null;
    }
  }

  let is_managed_server_address = isNullValue(
    formattedData.openvpn_profile.server_config.managed_server_address_info
  );
  if (is_managed_server_address) {
    formattedData.openvpn_profile.server_config.managed_server_address_info = null;
  }
  return formattedData;
}

function isNullValue(data) {
  return Object.values(data).every(value =>
    value === null || value === "" || value === undefined ? true : false
  );
}

export default {
  IP_PATTERN,
  downloadBlobFile,
  CLIENT,
  SERVER,
  IP_REGEX,
  SUBNET_REGEX,
  applicationRulePayload,
  vpnProfilePayload
};
