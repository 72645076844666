import Vue from "vue";
import Vuex from "vuex";

import { localStoragePlugin } from "@/store/plugins";

import common from "@/store/common";
import snackbar from "@/store/snackbar";
import login from "@/modules/login/store";
import environments from "@/modules/environments/store";
import users from "@/modules/users/store";
import devices from "@/modules/devices/store";
import groups from "@/modules/groups/store";
import dataPath from "@/modules/data-path/store";
import auditLog from "@/modules/logs/audit-log/store";
import thresholds from "@/modules/admin/thresholds/store";
import versionsManagement from "@/modules/admin/versions-management/store";
import systemLog from "@/modules/logs/system-log/store";
import forgotPassword from "@/modules/forgot-password/store";
import resetPassword from "@/modules/reset-password/store";
import appLayout from "@/layouts/app/store";
import loader from "./loader";
import devicesConnectivity from "@/modules/admin/usage-monitoring/store";
import messagingLog from "@/modules/logs/messaging-log/store";
import otpVerification from "@/modules/otp-verification/store";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    common: common,
    loader: loader,
    snackbar: snackbar,
    login: login,
    environments: environments,
    users: users,
    devices: devices,
    groups: groups,
    dataPath: dataPath,
    thresholds: thresholds,
    auditLog: auditLog,
    versionsManagement: versionsManagement,
    systemLog: systemLog,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    appLayout: appLayout,
    devicesConnectivity: devicesConnectivity,
    messagingLog: messagingLog,
    otpVerification: otpVerification
  },
  plugins: [localStoragePlugin.plugin],
  state: {},
  mutations: {
    RESTORE_MUTATION: localStoragePlugin.RESTORE_MUTATION
  },
  actions: {}
});
