import store from "@/store";
import i18n from "@/plugins/i18n";

export class UnauthorizedError extends Error {}
export class DeviceUpdateForbiddenError extends Error {}
export class DeviceDeleteForbiddenError extends Error {}
export class EnvironmentNotFoundError extends Error {}
export class RequiredFieldMissingError extends Error {}
export class DeviceNameExistsError extends Error {}
export class DeviceParametersExistsError extends Error {}
export class IdNotFoundError extends Error {}
export class EnvironmentExistsError extends Error {}
export class GroupNameExistsError extends Error {}
export class EmailAlreadyExistsError extends Error {}
export class UsernameAlreadyExistsError extends Error {}
export class PasswordValidationError extends Error {}
export class ApplicationRuleNameAlreadyExistsError extends Error {}
export class ApplicationRuleAlreadyExistsError extends Error {}
export class PortRangeOverlapError extends Error {}
export class InvalidDevicesRecordFileError extends Error {}
export class VpnProfileNameAlreadyExists extends Error {}
export class VpnGenerateError extends Error {}
export class MetaFileExistsError extends Error {}
export class DuplicateCustomKeyError extends Error {}
export class NotAllowedCustomKeysError extends Error {}
export class BrokenRfRecordingFileUrlError extends Error {}
export class FailedToDownloadRfRecordingError extends Error {}
export class NotAllowedPrivateProfileError extends Error {}
export class ForbiddenError extends Error {}
export class InternalServerError extends Error {}
export class TwoFactorAuthDeactivationFailError extends Error {}

export class IncorrectUsernamePasswordError extends Error {
  constructor(remainingAttempts) {
    super();
    this.remainingAttempts = remainingAttempts;
  }
}
export class TemporaryPasswordMessageError extends Error {
  constructor(message) {
    super();
    this.message = message;
  }
}
export class LoginFailedError extends Error {}
export class TwoFAActivatedError extends Error {}
export class InvalidOTP extends Error {}
export class TwoFANotInitiatedError extends Error {}
export class TwoFANotActivatedError extends Error {}

/**
 * Handles error received from a failed HTTP request.
 * @param {Error} error received error.
 */
export function handleError(error, variables = []) {
  if (error instanceof UnauthorizedError) {
    store.dispatch("login/logout").finally(() => {
      window.location.href = "/login";
    });
  } else if (error instanceof DeviceUpdateForbiddenError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.deviceUpdateForbidden"),
      id: "error--device-update-forbidden"
    });
  } else if (error instanceof DeviceDeleteForbiddenError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.deviceDeleteForbidden"),
      id: "error--device-delete-forbidden"
    });
  } else if (error instanceof EnvironmentNotFoundError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.environmentNotFound"),
      id: "error--environment-not-found"
    });
  } else if (error instanceof DeviceNameExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.deviceNameExists"),
      id: "error--device-exists"
    });
  } else if (error instanceof DeviceParametersExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.deviceParameterExists"),
      id: "error--param-exists"
    });
  } else if (error instanceof PortRangeOverlapError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.portRangeOverlapError"),
      id: "error--param-exists"
    });
  } else if (error instanceof RequiredFieldMissingError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.fieldMissing"),
      id: "error--field-missing"
    });
  } else if (error instanceof IdNotFoundError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.idNotFound"),
      id: "error--id-not-found"
    });
  } else if (error instanceof GroupNameExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.groupNameExists"),
      id: "error--group-name-exists"
    });
  } else if (error instanceof EnvironmentExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.environmentExists"),
      id: "error--environment-exists"
    });
  } else if (error instanceof EmailAlreadyExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.emailExists"),
      id: "error--email-exists"
    });
  } else if (error instanceof UsernameAlreadyExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.usernameExists"),
      id: "error--username-exists"
    });
  } else if (error instanceof PasswordValidationError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.passwordInvalid"),
      id: "error--password-invalid"
    });
  } else if (error instanceof ApplicationRuleNameAlreadyExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.applicationRuleNameExists"),
      id: "error--application-rule-exists"
    });
  } else if (error instanceof ApplicationRuleAlreadyExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.applicationRuleExists"),
      id: "error--application-rule-exists"
    });
  } else if (error instanceof InvalidDevicesRecordFileError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.invalidDevicesRecordFile"),
      id: "error--invalid-devices-record-file"
    });
  } else if (error instanceof VpnProfileNameAlreadyExists) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.vpnProfileNameAlreadyExists"),
      id: "error--vpn-profile-name-already-exists"
    });
  } else if (error instanceof VpnGenerateError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.vpnGenerate"),
      id: "error--vpn-generate"
    });
  } else if (error instanceof MetaFileExistsError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.metaFileAlreadyExist", variables),
      id: "error--meta-file"
    });
  } else if (error instanceof DuplicateCustomKeyError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.duplicateCustomKey"),
      id: "error--duplicate-custom-key"
    });
  } else if (error instanceof NotAllowedCustomKeysError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.notAllowedCustomKeys"),
      id: "error--not-allowed-custom-keys"
    });
  } else if (error instanceof BrokenRfRecordingFileUrlError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.brokenRfRecordingFileUrlError"),
      id: "error--broken-link"
    });
  } else if (error instanceof FailedToDownloadRfRecordingError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.failedToDownloadRfRecordingError"),
      id: "error--failed-to-download-rf-recordings"
    });
  } else if (error instanceof NotAllowedPrivateProfileError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.notAllowedPrivateProfileError"),
      id: "error--not-allowed-private-profile"
    });
  } else if (error instanceof ForbiddenError) {
    let data = {
      text: i18n.t("error.forbiddenError"),
      shown: true,
      color: "error",
      id: "error--forbidden"
    };
    store.dispatch("snackbar/setMessage", data);
  } else if (error instanceof InternalServerError) {
    let data = {
      text: i18n.t("error.internalServerError"),
      shown: true,
      color: "error",
      id: "error--internal-server"
    };
    store.dispatch("snackbar/setMessage", data);
  } else if (error instanceof IncorrectUsernamePasswordError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.incorrectUsernamePassword"),
      id: "error--incorrect-username-password"
    });
  } else if (error instanceof TemporaryPasswordMessageError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: error.message,
      id: "error--temporary-password-message"
    });
  } else if (error instanceof LoginFailedError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.loginFailed"),
      id: "error--login-failed"
    });
  } else if (error instanceof TwoFAActivatedError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.twoFAActivatedError"),
      id: "error--two-fa-activated-error"
    });
  } else if (error instanceof InvalidOTP) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.invalidOtpError"),
      id: "error--invalid-otp-error"
    });
  } else if (error instanceof TwoFANotInitiatedError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.twoFANotInitiatedError"),
      id: "error--two-fa-not-initiated-error"
    });
  } else if (error instanceof TwoFANotActivatedError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.twoFANotActivatedError"),
      id: "error--two-fa-not-activated-error"
    });
  } else if (error instanceof TwoFactorAuthDeactivationFailError) {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.twoFactorDeactivateFail"),
      id: "error--2fa-deactivation-fail"
    });
  } else {
    store.dispatch("snackbar/setMessage", {
      color: "error",
      text: i18n.t("error.generalError"),
      id: "error--general"
    });
  }
}
