<template>
  <v-app :dark="isDarkTheme" class="layout__auth--background">
    <v-scale-transition origin="center center">
      <router-view />
    </v-scale-transition>
  </v-app>
</template>

<script>
export default {
  name: "auth-layout",

  computed: {
    isDarkTheme: function() {
      return this.$store.state.common.theme.isDark;
    }
  }
};
</script>

<style scoped>
.layout__auth--background {
  background-image: url("~@/assets/shutterstock-tansparent.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>
